import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react'
import classNames from 'classnames'

import Button from './button'
import FileDragAndDrop from './file-drag-and-drop'
import defaultLogoImage from '../assets/utm_dog_bw_right.png'
import { messages } from '../core/variables'
import styles from '../styles/img-uploader.module.scss'

interface ImgUploaderProps {
  imageFile: string
  setImageFile: Dispatch<SetStateAction<string>>
  uploadButtonText?: string
  className?: string
}

export function ImgUploader({
  imageFile,
  setImageFile,
  uploadButtonText,
  className,
}: ImgUploaderProps) {
  const [showUploader, setShowUploader] = useState(false)
  const [error, setError] = useState('')
  const [uploadInProgress, setUploadInProgress] = useState(false)

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setUploadInProgress(true)
      const file = acceptedFiles.pop()
      if (file) {
        const { type, size } = file
        if (type && size && type.indexOf('image/') !== -1 && size < 1038383) {
          setImageFile((currFile) => {
            URL.revokeObjectURL(currFile)

            setUploadInProgress(false)
            return URL.createObjectURL(file)
          })

          setShowUploader(false)
        }
      }
    } else {
      setError(messages.fileUploadError)
    }
  }, [])

  // Reset upload states
  useEffect(() => {
    if (!showUploader) {
      setError('')
      setUploadInProgress(false)
    }
  }, [showUploader])

  return (
    <div
      className={classNames(styles.logoComponent, className, {
        [styles.showUploader]: showUploader,
      })}
    >
      {showUploader ? (
        <>
          <FileDragAndDrop
            uploadButtonText={uploadButtonText}
            className={styles.dragAndDrop}
            error={error}
            onDrop={onDrop}
            inProgress={uploadInProgress}
          />
          <Button
            onClick={() => {
              setShowUploader(false)
            }}
            type="clear"
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={() => {
              setShowUploader(true)
            }}
            type="flatPink"
          >
            Replace
          </Button>
          {imageFile !== defaultLogoImage && (
            <Button
              className={styles.deleteButton}
              onClick={async () => {
                setImageFile((currFile) => {
                  URL.revokeObjectURL(currFile)
                  return defaultLogoImage
                })
              }}
              type="delete"
            />
          )}
        </>
      )}
    </div>
  )
}
