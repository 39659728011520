import React, { useState } from 'react'
import classNames from 'classnames'

import styles from '../styles/delete-button-with-confirmation.module.scss'
import Button from './button'
import ConfirmationButtons from './confirmation-buttons'

interface Props {
  children: React.ReactNode
  onClick: () => void
  className?: string
  odd?: boolean
}

export default function DeleteButtonWithConfirmation({
  onClick,
  children,
}: Props): React.ReactElement {
  const [removeActive, setRemoveActive] = useState(false)

  return (
    <div
      className={classNames(styles.deleteButtonContainer, {
        [styles.deleteActionActive]: removeActive,
      })}
    >
      <div className={styles.message}>
        <Button type="delete" onClick={(): void => setRemoveActive(true)} />
        <div className={styles.content}>
          {removeActive && (
            <>
              {children}
              <ConfirmationButtons
                type="compact"
                className={styles.confirmationButtons}
                onYes={(): void => {
                  setRemoveActive(false)
                  onClick()
                }}
                onNo={(): void => {
                  setRemoveActive(false)
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}
