import { useState } from 'react'
import { Link } from 'react-router-dom'

import Button from './button'
import styles from '../styles/top-bar.module.scss'
import utmDog from '../assets/utm-dog-text-logo-color.png'
import Layout from './layout'

export default function TopBar(): React.ReactElement {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggle = isExpanded ? styles.expanded : styles.notExpanded
  const linkExpanded = isExpanded ? styles.linkExpanded : styles.linkNotExpanded
  const btnExpanded = isExpanded ? styles.btnExpanded : styles.btnNotExpanded

  // Need to only allow clicking the link to toggle state when in expanded mode ???

  return (
    <nav className={styles.navContainer}>
      <Layout width={1200}>
        <Button type="image" to="/" className={styles.dog}>
          <img src={utmDog} alt="utm.dog" />
        </Button>
        <button
          className={styles.hamburger}
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="white"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        <div className={toggle}>
          <Link
            className={linkExpanded}
            to="/"
            onClick={() => {
              setIsExpanded(false)
            }}
          >
            Create UTM codes
          </Link>
          <Link
            className={linkExpanded}
            to="/utm-wiki"
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            UTM wiki
          </Link>
          <Button
            type="containerPink"
            href="https://upl.inc/8aM72G"
            newTab
            className={btnExpanded}
            id="tryUplifterNav"
            onClick={() => {
              setIsExpanded(!isExpanded)
            }}
          >
            Upgrade to Uplifter
          </Button>
        </div>
      </Layout>
    </nav>
  )
}
