import classNames from 'classnames'

import useMobile from '../hooks/useMobile'
import styles from '../styles/row.module.scss'

interface Props {
  className?: string
  children?: React.ReactNode
  align?: 'flex-start' | 'flex-end' | 'center' | 'space-between'
  vAlign?: 'stretch' | 'flex-start' | 'flex-end' | 'center'
  style?: object
  width?: number
  centerAtMobile?: boolean
  odd?: boolean
}

export default function Row({
  children,
  className,
  style,
  width,
  odd,
  centerAtMobile,
}: Props): React.ReactElement {
  const mobile = useMobile(769)
  const oddClassName = odd ? styles.odd : ''
  return (
    <div
      className={`${oddClassName} ${
        centerAtMobile ? styles.rowCenterAtMobile : styles.row
      } ${className}`}
      style={{
        ...style,
        justifyContent: 'center',
        alignItems: 'stretch',
        maxWidth: mobile ? 'auto' : width,
      }}
    >
      {children}
    </div>
  )
}

interface FormRowProps {
  className?: string
  children?: React.ReactNode
  align?: 'flex-start' | 'flex-end' | 'center' | 'space-between'
  vAlign?: 'stretch' | 'flex-start' | 'flex-end' | 'center'
  style?: object
  width?: number
  centerAtMobile?: boolean
  odd?: boolean
  single?: boolean
  wideFields?: boolean
}

export function FormRow({
  className,
  single,
  wideFields,
  children,
  ...res
}: FormRowProps): React.ReactElement {
  const rowClassNames = classNames(className, styles.formRow, {
    [styles.single]: single,
    [styles.wideFields]: wideFields,
  })
  return (
    <Row className={rowClassNames} {...res}>
      {children}
    </Row>
  )
}

interface SlotProps {
  children?: React.ReactNode
  width?: number
  className?: string
  style?: object
  padding?: string
  align?: 'flex-start' | 'flex-end' | 'center'
  vAlign?: 'flex-start' | 'flex-end' | 'center'
  centerAtMobile?: boolean
  containsForm?: boolean
}

export function Slot({
  children,
  width,
  className,
  style,
  padding,
  align,
  vAlign,
  centerAtMobile,
}: SlotProps): React.ReactElement {
  const mobile = useMobile(769)
  return (
    <div
      className={classNames(className, {
        [styles.slotCenterAtMobile]: centerAtMobile,
        [styles.slot]: !centerAtMobile,
      })}
      style={{
        ...style,
        maxWidth: mobile ? 'auto' : width,
        padding,
        ...(align
          ? { display: 'flex', justifyContent: align, alignItems: vAlign }
          : {}),
      }}
    >
      {children}
    </div>
  )
}

Slot.defaultProps = {
  className: '',
  style: {},
}

interface LabelSlotProps {
  children?: React.ReactNode
  className?: string
  style?: object
  column?: boolean
  topAlign?: boolean
  noPadding?: boolean
}

export function LabelSlot({
  className,
  children,
  column,
  topAlign = false,
  noPadding = false,
  ...res
}: LabelSlotProps): React.ReactElement {
  const elementClassNames = classNames(className, styles.labelSlot, {
    [styles.column]: column,
    [styles.topAlign]: topAlign,
    [styles.noPadding]: noPadding,
  })
  return (
    <Slot className={elementClassNames} {...res}>
      {children}
    </Slot>
  )
}

export function FieldSlot({
  className,
  children,
  column,
  noPadding = false,
  ...res
}: LabelSlotProps): React.ReactElement {
  const elementClassNames = classNames(className, styles.fieldSlot, {
    [styles.column]: column,
    [styles.noPadding]: noPadding,
  })
  return (
    <Slot className={elementClassNames} {...res}>
      {children}
    </Slot>
  )
}
