import React, { useRef, useState } from 'react'
import QRCode from 'qrcode.react'

import Button from './button'
import styles from '../styles/qr-code-image.module.scss'

interface QRCodeImageProps {
  canvas: React.RefObject<HTMLCanvasElement>
  fgColor: string
  bgColor: string
  code: string
  showLogo: boolean
  logoImage: string
  disableCopy: boolean
  selectedType: string
  children: any
}

export function QRCodeImage({
  canvas,
  fgColor,
  bgColor,
  code,
  showLogo,
  logoImage,
  disableCopy,
  selectedType,
  children,
}: QRCodeImageProps): React.ReactElement {
  const [defaultShowUploader, setDefaultShowUploader] = useState(false)

  const previewRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div className={styles.hide}>
        <canvas ref={canvas} height={200} width={200} />
      </div>
      <div className={styles.qrCodeWrapper}>
        <div ref={previewRef}>
          <QRCode
            onClick={() => {
              setDefaultShowUploader(!defaultShowUploader)
            }}
            fgColor={fgColor}
            bgColor={bgColor}
            value={code}
            level={showLogo ? 'H' : 'M'}
            size={200}
            renderAs="canvas"
            imageSettings={
              showLogo
                ? {
                    src: logoImage,
                    width: 60,
                    height: 60,
                    excavate: true,
                  }
                : undefined
            }
          />
        </div>
        {!disableCopy && (
          <Button
            className={styles.copyButton}
            type="copy"
            align="left"
            onClick={async () => {
              if (previewRef && previewRef.current) {
                const c = previewRef.current.querySelector('canvas')
                if (c) {
                  c.toBlob(
                    // @ts-ignore
                    async (blob: Blob) => {
                      const data = new ClipboardItem({
                        'image/png': blob,
                      })
                      await navigator.clipboard.write([data])
                    },
                    `image/${selectedType}`,
                    1.0,
                  )
                }
              }
            }}
          >
            Copy
          </Button>
        )}
        {children}
      </div>
    </>
  )
}
