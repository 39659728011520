import React from 'react'
import classNames from 'classnames'

import styles from '../styles/confirmation-buttons.module.scss'
import Button, { ButtonRow } from './button'

interface Props {
  yesLabel?: string
  noLabel?: string
  onYes?: () => void
  onNo?: () => void
  className?: string
  disabled?: boolean
  type?: 'normal' | 'simple' | 'compact' | 'warning'
  hideDisabled?: boolean
  centerAlign?: boolean
  children?: React.ReactChild
}

export default function ConfirmationButtons({
  onYes,
  onNo,
  yesLabel,
  noLabel,
  className = '',
  disabled = false,
  type = 'normal',
  hideDisabled = false,
  centerAlign = false,
  children,
}: Props): React.ReactElement | null {
  const rowClassName = classNames(className, styles.wrapper, {
    [styles[type]]: true,
    [styles.centerAlign]: centerAlign,
  })

  const isPillButton = !(type === 'simple' || type === 'warning')
  let leftButtonType = type === 'simple' ? 'text' : 'hollowBorder'
  leftButtonType = type === 'warning' ? 'container' : leftButtonType
  const rightButtonType = type === 'warning' ? 'flatPink' : 'container'

  return (
    <ButtonRow className={rowClassName}>
      {children}
      <div className={styles.multiButtons}>
        {!(hideDisabled && disabled) && (
          <Button
            type={rightButtonType}
            className={styles.button}
            pill={isPillButton}
            submit
            disabled={disabled}
            onClick={() => {
              if (onYes) {
                onYes()
              }
            }}
          >
            {yesLabel}
          </Button>
        )}
        {onNo && (
          <Button
            className={styles.button}
            pill={isPillButton}
            type={leftButtonType}
            onClick={(): void => {
              onNo()
            }}
          >
            {noLabel}
          </Button>
        )}
      </div>
    </ButtonRow>
  )
}

ConfirmationButtons.defaultProps = {
  visible: true,
  yesLabel: 'Yes',
  noLabel: 'No',
}
