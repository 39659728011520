import React from 'react'
import { generatedStructure, replaceChecks } from '../core/generated-structure'

export const getValuesArray = (
  type: Platforms,
): CampaignCodeGeneratorValues => {
  const formStructure: CampaignCodeGeneratorValues = {
    'landing-page': {
      fieldName: 'Landing page URL',
      value: '',
    },
  }

  generatedStructure[type]?.forEach(({ fieldID, fieldName, prefix }) => {
    formStructure[fieldID] = {
      fieldName,
      prefix,
      value: '',
    }
  })

  return formStructure
}

export const allDataOK = (data: CampaignCodeGeneratorValues, type: Platforms) =>
  data['landing-page'].value !== '' &&
  generatedStructure[type]?.every((item) => {
    if (!item.required) return true

    return data[item.fieldID]?.value !== ''
  })

export const prepareInput = (value: string): string => {
  if (value && value !== '') {
    let result = value

    replaceChecks.forEach((item) => {
      const pattern = item.fn
      // @ts-ignore
      result = result.replace(pattern[0], pattern[1])
    })
    return result
  }

  return value
}

export const makeLinkSecure = (link: string) => {
  if (
    link &&
    typeof link === 'string' &&
    link.search(/(https:\/\/)|(http:\/\/)/gi) === -1 &&
    link.search(/.\../i) !== -1
  ) {
    return `https://${link}`
  }
  return link
}

export const isValidUrl = (url: string): boolean =>
  typeof url === 'string' &&
  url !== '' &&
  url.length > 1 &&
  url.search(/(https:\/\/)|(http:\/\/)/gi) !== -1

export const copyString = (toCopy: string | string[]): void => {
  const input = document.createElement('textarea')
  input.style.opacity = '0'
  input.style.pointerEvents = 'none'
  input.style.position = 'absolute'
  input.value = Array.isArray(toCopy) ? toCopy.join('\r\n') : toCopy
  document.body.append(input)
  input.select()
  document.execCommand('copy')
  document.body.removeChild(input)
}

export function getAnchorFromString(url: string, masterPrefix = '?'): string {
  const hasAnchor = url.indexOf('#') !== -1
  let useAnchor = ''
  if (hasAnchor) {
    const searchRegEx = new RegExp(`(#.*)[${masterPrefix}]|(#.*)`, 'gi')
    const urlMatch = url.match(searchRegEx)
    if (urlMatch && urlMatch.length > 0) {
      useAnchor = urlMatch[0].replace('?', '')
    }
  }
  return useAnchor
}

export const outerSpanClick = (
  event: React.MouseEvent | React.KeyboardEvent,
) => {
  if (
    event.target instanceof Element &&
    event.target.tagName.toLowerCase() === 'span'
  ) {
    event.target.querySelector('input')?.focus()
  }
}

export function shuffle(array: any[]) {
  const newArray = array
  let currentIndex = newArray.length
  let randomIndex
  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    // And swap it with the current element.
    ;[newArray[currentIndex], newArray[randomIndex]] = [
      newArray[randomIndex],
      newArray[currentIndex],
    ]
  }
  return newArray
}
