import { ReactElement } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'

import MiniSearchBar from '../components/mini-search-bar'
import TwoColumns, {
  Column,
  InnerBox,
  OuterBox,
} from '../components/two-columns'
import UplifterInfo from '../components/uplifter-info'
import WikiNavBox from '../components/wiki-navbox'
import utmStyles from '../styles/utm-wiki.module.scss'
import styles from '../styles/campaign-code-generator.module.scss'
import ToolTip from '../components/tooltip'

interface BlogTemplateProps {
  title: string
  category: string
  children: string
  excerpt?: string
}

export default function BlogTemplate({
  title,
  children,
  category,
}: BlogTemplateProps) {
  return (
    <TwoColumns>
      <Column main>
        <OuterBox>
          <InnerBox>
            <h1>{title}</h1>

            <ReactMarkdown
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
              // @ts-ignore
              components={{
                span: ({ className, ...props }) => {
                  // eslint-disable-next-line
                  if (className && className.indexOf('tooltip') > -1) {
                    const message = props.children.find(
                      (child) =>
                        (child as ReactElement).props.className.indexOf(
                          'message',
                        ) > -1,
                    )

                    const icon = props.children.find(
                      (child) =>
                        (child as ReactElement).props.className.indexOf(
                          'icon',
                        ) > -1,
                    )

                    return (
                      <ToolTip
                        message={
                          // // eslint-disable-next-line react/jsx-no-useless-fragment
                          // <ReactMarkdown>
                          //   {(message as ReactElement).props.children.join(
                          //     '\n',
                          //   )}
                          // </ReactMarkdown>
                          (message as ReactElement).props.children
                        }
                      >
                        <span
                          {...(icon as ReactElement).props}
                          // eslint-disable-next-line react/no-unknown-property
                          node={undefined}
                        />
                      </ToolTip>
                    )
                  }

                  if (className && className.indexOf('tooltip') > -1) {
                    const message = props.children.find(
                      (child) =>
                        (child as ReactElement).props.className.indexOf(
                          'message',
                        ) > -1,
                    )

                    const icon = props.children.find(
                      (child) =>
                        (child as ReactElement).props.className.indexOf(
                          'icon',
                        ) > -1,
                    )

                    return (
                      <ToolTip
                        message={
                          // // eslint-disable-next-line react/jsx-no-useless-fragment
                          // <ReactMarkdown>
                          //   {(message as ReactElement).props.children.join(
                          //     '\n',
                          //   )}
                          // </ReactMarkdown>
                          (message as ReactElement).props.children
                        }
                      >
                        <span
                          {...(icon as ReactElement).props}
                          // eslint-disable-next-line react/no-unknown-property
                          node={undefined}
                        />
                      </ToolTip>
                    )
                  }

                  return <span {...props} />
                },
              }}
            >
              {children}
            </ReactMarkdown>
          </InnerBox>
        </OuterBox>
      </Column>

      <Column fixed side>
        <InnerBox className={utmStyles.innerBox}>
          <MiniSearchBar />
        </InnerBox>

        <OuterBox>
          <InnerBox className={styles.rightInner}>
            <WikiNavBox blogLabel={category} blogTitle={title} />
          </InnerBox>
        </OuterBox>
        <OuterBox>
          <InnerBox className={styles.rightInner}>
            <UplifterInfo />
          </InnerBox>
        </OuterBox>
      </Column>
    </TwoColumns>
  )
}
