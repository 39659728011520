import shortid from 'shortid'
import { getAnchorFromString } from '.'

export default function generateCode(
  values: CampaignCodeGeneratorValues,
  codeType: Platforms,
): GeneratedCode {
  if (Object.keys(values).length === 0) return {} as GeneratedCode

  const params = JSON.parse(
    JSON.stringify(values),
  ) as CampaignCodeGeneratorValues

  const link = params['landing-page'].value

  delete params['landing-page']

  const code: string = Object.values(params).reduce(
    (accStr: string, { prefix, value }, index) => {
      if (value === '') return accStr
      return `${accStr}${index > 0 ? '&' : ''}${prefix}${value}`
    },
    link.indexOf('?') > -1 ? '&' : '?',
  )

  const useAnchor = getAnchorFromString(link, '?')

  const fullUrl = link.replace(useAnchor, '').concat(code, useAnchor)

  return {
    id: shortid.generate(),
    codeType,
    created: new Date(Date.now()).getTime(),
    fullUrl,
    link,
    code,
    params,
  }
}
