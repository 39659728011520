import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../context/data-context'
import utmStyles from '../styles/utm-wiki.module.scss'
import gaEvent from '../utils/tracking'

export default function MiniSearchBar() {
  const [query, setQuery] = useState('')

  // Checks if filteredArticles has length
  const [noResults, setNoResults] = useState(false)
  const [changeTimeout, setChangeTimeout] = useState<number | null>(null)

  const { data, error } = useContext(DataContext)

  if (error) {
    return <div>An error occurred!</div>
  }

  // Checks if input field is currently being typed in
  const searchResultDiv = useMemo(
    () =>
      query ? utmStyles.searchResultDivShow : utmStyles.searchResultDivHide,
    [query],
  )

  const filteredData = useMemo(() => {
    const validResults = data.filter(({ title }) =>
      JSON.stringify(title).toLowerCase().includes(query.toLowerCase()),
    )

    if (query !== '' && validResults.length === 0) setNoResults(true)
    else setNoResults(false)

    return validResults
  }, [query])

  const keyDownTimer = useCallback(() => {
    setChangeTimeout(
      window.setTimeout(() => {
        gaEvent('user_query', { query })
      }, 2000),
    )
  }, [query])

  useEffect(() => {
    if (noResults) keyDownTimer()
  }, [noResults, query])

  if (data) {
    return (
      <>
        <input
          type="text"
          placeholder="Search wiki articles"
          className={utmStyles.miniSearchInput}
          value={query}
          onChange={(e) => {
            if (changeTimeout) {
              window.clearTimeout(changeTimeout)
            }

            setQuery(e.target.value)
          }}
        />
        <div className={searchResultDiv}>
          {filteredData.length === 0 ? (
            <p id="noResultsFound">No results found</p>
          ) : (
            filteredData.map(({ title, slug }) => (
              <Link
                to={`/utm-wiki/${slug}`}
                key={title}
                onClick={() => {
                  setQuery('')
                }}
              >
                <h2 className={utmStyles.searchResultLink}>{title}</h2>
              </Link>
            ))
          )}
        </div>
      </>
    )
  }

  return <div>Loading...</div>
}
