export const urlLength = 1024

export const queryLength = 255

export const replaceChecks: ReplaceCheck[] = [
  {
    name: 'SPACE',
    fn: [/\s/g, '_'],
  },
  {
    name: 'UPPER_CASE',
    fn: [/[A-Z]/g, (a) => a.toLowerCase()],
  },
  {
    name: 'SPECIAL_CHARS',
    fn: [/[?=&]/g, ''],
  },
]

export const generatedStructure: Partial<Record<Platforms, GeneratorField[]>> =
  {
    'Web form': [
      {
        fieldID: 'source',
        fieldName: 'Source',
        helpText:
          'The name of the platform, publisher or website users clickthrough from, e.g. google search, facebook or the new york times.',
        prefix: 'utm_source=',
        required: true,
      },
      {
        fieldID: 'medium',
        fieldName: 'Medium',
        helpText:
          "The type of marketing activity users clickthrough to reach your website. Sometimes referred to as 'Channel', e.g. paid search, paid social, organic social, display, affiliates, offline.",
        prefix: 'utm_medium=',
        required: true,
      },
      {
        fieldID: 'campaign',
        fieldName: 'Campaign',
        helpText:
          "The name used to describe a group of marketing activity for a specific period, product or promotion, e.g. 'christmas-2022', 'black-friday, 'womens-shoe-sale', 'free-delivery-weekend'.",
        prefix: 'utm_campaign=',
        required: true,
      },
      {
        fieldID: 'content',
        fieldName: 'Content',
        helpText:
          "The name of the creative, asset or subject line. Use this parameter for AB testing different ad types in the same location, e.g. 'product', 'lifestyle', 'free-delivery-weekend'.",
        prefix: 'utm_content=',
        required: false,
      },
      {
        fieldID: 'term',
        fieldName: 'Term',
        helpText:
          'For paid search only. The name of the paid search keyword, phase, or type (broad/exact/brand/generic), e.g. reward-credit-cards, credit-cards, brand.',
        prefix: 'utm_term=',
        required: false,
      },
      {
        fieldID: 'source_platform',
        fieldName: 'Source Platform',
        helpText:
          'Identify the platform where the marketing activity was undertaken.',
        prefix: 'utm_source_platform=',
        required: false,
        collapsible: true,
      },
      {
        fieldID: 'creative_format',
        fieldName: 'Creative Format',
        helpText:
          'The type of creative users clicked through on, e.g. text link, image, gif or video.',
        prefix: 'utm_creative_format=',
        required: false,
        collapsible: true,
      },
      {
        fieldID: 'marketing_tactic',
        fieldName: 'Marketing Tactic',
        helpText:
          "Generally, this refers to the targeting criteria applied to the campaign, e.g. 'remarketing' or 'prospecting'.",
        prefix: 'utm_marketing_tactic=',
        required: false,
        collapsible: true,
      },
    ],
  }

export const generatorTypes = Object.keys(generatedStructure) as Platforms[]
