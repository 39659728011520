import classNames from 'classnames'

import newWindow from '../assets/new-window.svg'
import styles from '../styles/labels.module.scss'

interface PillProps {
  className?: string
  title?: string
  link?: string
}

export function Pill({
  className,
  title = 'Create account',
  link,
}: PillProps): React.ReactElement | null {
  if (link) {
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <span className={classNames(className, styles.pill)}>
          {title} <img src={newWindow} alt="Open in new window" />
        </span>
      </a>
    )
  }

  return <span className={classNames(className, styles.pill)}>{title}</span>
}

interface LabelProps {
  id?: string
  children: any
  className?: string
  onClick?: (event: React.MouseEvent) => void
  optional?: boolean
  modalHeading?: boolean
}

export default function Label({
  id,
  children,
  className,
  onClick,
  optional,
  modalHeading,
}: LabelProps): React.ReactElement {
  const labelClassNames = classNames(className, styles.label, {
    [styles.modalHeading]: modalHeading,
  })

  return (
    <label
      role="presentation"
      htmlFor={id}
      className={labelClassNames}
      onClick={onClick}
    >
      {children}
      {optional && (
        <>
          {' '}
          <b className={styles.optional}>(optional)</b>
        </>
      )}
    </label>
  )
}
