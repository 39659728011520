import React from 'react'
import FileSaver from 'file-saver'

import Button from './button'
import { InnerBox, OuterBox } from './two-columns'
import excelFile from '../assets/Best_Practice_Free_UTM_Spreadsheet_Excel_v.1.0.xlsx'
import styles from '../styles/code-generator-utm-spreadsheet.module.scss'

export default function CodeGeneratorUtmSpreadsheet() {
  return (
    <OuterBox className={styles.outerBox}>
      <InnerBox>
        <h2>When to use a UTM spreadsheet</h2>
        <p>
          A simple web UTM builder like utm.dog is ideal for small companies
          making infrequent campaign links. When you need to create, share and
          report on lots of campaign links, companies often use a UTM
          spreadsheet. There are limitations, but spreadsheets can help teams
          create links in the same consistent way.
        </p>
        <div className={styles.separator} />
        <h2>Pros</h2>
        <ul className="logoList">
          <li>Gets everyone using the same UTM taxonomy and values</li>
          <li>
            Can be faster for creating an array of links for a big campaign
          </li>
          <li>Users can easily share links with colleagues and agencies</li>
        </ul>
        <h2>Cons</h2>
        <ul className="logoList negative">
          <li>Requires someone to own, setup and maintain the spreadsheet</li>
          <li>
            Functionality is limited when shared through Sharepoint or Google
            Sheets
          </li>
          <li>Formulas can break, causing loss of campaign tracking</li>
          <li>
            It's hard to stop users using old versions or “hacking” the
            spreadsheet
          </li>
          <li>
            Duplicate links can be mistakenly created, causing campaign data to
            be blended
          </li>
          <li>No advanced link management or page checking functionality</li>
          <li>Can't automatically create short links or QR codes</li>
        </ul>
        <div className={styles.separator} />
        <h2>Alternatives</h2>
        <p>
          We created Uplifter as an all-in-one campaign analytics tool for
          teams. It gives you all the benefits of a UTM spreadsheet, with:
        </p>
        <ul className="logoList">
          <li>Dropdowns and date pickers</li>
          <li>Admin approval flow</li>
          <li>Landing page monitoring</li>
          <li>Branded short links &amp; QR codes</li>
          <li>One-click campaign reports</li>
          <li>Google, Adobe &amp; Salesforce integrations</li>
          <li>Friendly, human support</li>
        </ul>
        <div className={styles.separator} />
        <h2>Download UTM spreadsheets</h2>
        <p>
          Here are two best-practice UTM spreadsheet templates you can download:
        </p>
        <ul className="logoList">
          <li>
            <strong>Google Sheets / Workspace:</strong> download Cardinal Path's
            Excellent Google Sheets builder{' '}
            <a
              href="https://docs.google.com/spreadsheets/d/1fzZ8YxwNwC1elYbzMhWZTurbwGHEzI1ILWXNvCxMMR4/template/preview"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            . This allows you to save and share campaign links across multiple
            users in Google Cloud.
          </li>
          <li>
            <strong>Microsoft Excel / Microsoft 365:</strong> download our Excel
            version{' '}
            <Button
              type="link"
              onClick={async (e: React.MouseEvent) => {
                e.preventDefault()

                await FileSaver.saveAs(
                  excelFile,
                  'UTMDog_Best_Practice_Free_UTM_Spreadsheet_Excel.xlsx',
                )
              }}
            >
              here
            </Button>
            . Upload this to your SharePoint site and share with your users.
          </li>
        </ul>
      </InnerBox>
    </OuterBox>
  )
}
