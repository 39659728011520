import { Dispatch, SetStateAction, useState } from 'react'

import Button from './button'
import { PassiveInput } from './inputs'
import CreateQrModal from './qr-code'
import { FieldSlot, FormRow, LabelSlot } from './row'
import ToolTip from './tooltip'
import { InnerBox, OuterBox } from './two-columns'
import styles from '../styles/show-latest-code.module.scss'
import { copyString } from '../utils'
import Label from './labels'

interface ShowLatestCodeProps {
  generatedCode: string
  imageFile: string
  setImageFile: Dispatch<SetStateAction<string>>
}

export function ShowLatestCode({
  generatedCode,
  imageFile,
  setImageFile,
}: ShowLatestCodeProps): React.ReactElement | null {
  const [showQrModal, setShowQrModal] = useState(false)

  return (
    <OuterBox>
      <InnerBox>
        <FormRow className={styles.noPadding}>
          <LabelSlot className={styles.labelSlot}>
            <Label id="link" className={styles.bold}>
              Your last created link
            </Label>
          </LabelSlot>
          <FieldSlot>
            <div className={styles.inlineRow}>
              <PassiveInput id="generated-code" value={generatedCode} />
              <Button
                type="copy"
                align="left"
                onClick={(): void => copyString(generatedCode)}
              >
                Copy
              </Button>
            </div>
          </FieldSlot>
        </FormRow>
        <FormRow className={styles.noPaddingBottom}>
          <LabelSlot className={styles.labelSlot}>
            <Label id="qr">QR code</Label>
            <ToolTip
              className={styles.tooltip}
              showOnRight
              message="An optional QR code which can be scanned by a mobile phone camera to access the landing page with campaign codes."
            />
          </LabelSlot>
          <FieldSlot>
            <div className={styles.qrCodeWrapper}>
              <CreateQrModal
                disableCopy
                returnQrCodeOnly
                code={generatedCode}
                key={generatedCode}
                imageFile={imageFile}
                setImageFile={setImageFile}
              >
                <Button
                  className={styles.customiseButton}
                  type="edit"
                  align="left"
                  onClick={(): void => setShowQrModal(true)}
                >
                  Customise
                </Button>
                <Button
                  className={styles.downloadButton}
                  type="download"
                  align="left"
                  onClick={(): void => setShowQrModal(true)}
                >
                  Download
                </Button>
              </CreateQrModal>
            </div>
            <p className={styles.saveNote}>
              Always test your <strong>QR code</strong> before use!
            </p>
          </FieldSlot>
        </FormRow>

        {showQrModal && (
          <CreateQrModal
            setShowModal={setShowQrModal}
            code={generatedCode}
            imageFile={imageFile}
            setImageFile={setImageFile}
          />
        )}
      </InnerBox>
    </OuterBox>
  )
}
