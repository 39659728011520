export const getLinkDomain = (link: string) => {
  const domain = link.match(
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/gim,
  )

  return domain ? domain[0] : link
}

export default function gaEvent(eventName: string, details?: Object): void {
  // window.gtag('event', eventName, details)
  if (window.dataLayer) {
    window.dataLayer.push({ event: eventName, ...details })
  }
}
