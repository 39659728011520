import { StrictMode } from 'react'
import ReactDOMClient from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import './core/core.scss'
import Router from './router'

Sentry.init({
  dsn: 'https://19b9ce0dba5d4047a06e9007a61da74f@o273852.ingest.sentry.io/4504254442373120',
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const root = ReactDOMClient.createRoot(
  document.getElementById('root') as HTMLDivElement,
)

root.render(
  <StrictMode>
    <Router />
  </StrictMode>,
)
