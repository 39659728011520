import { useEffect, useMemo, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import ScrollToTop from './components/scroll-to-top'
import BottomBar from './components/bottom-bar'
import TopBar from './components/top-bar'
import Layout from './components/layout'
import { BlogItem, fetchData } from './api/modulariseAPI'
import { DataContext } from './context/data-context'
import CampaignCodeGenerator from './pages/campaign-code-generator'
import UtmWiki from './pages/utm-wiki'
import BlogTemplate from './pages/blog-template'

export default function Router() {
  const [blogPosts, setBlogPosts] = useState<BlogItem[]>([])
  const [error, setError] = useState('')

  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const res = await fetchData()

        setBlogPosts(res)
      } catch (err) {
        setError(err as string)
      }
    }

    fetchBlogPosts()
  }, [])

  const context = useMemo(
    () => ({ data: blogPosts, error }),
    [blogPosts, error],
  )

  if (blogPosts) {
    return (
      <DataContext.Provider value={context}>
        <BrowserRouter>
          <ScrollToTop />
          <TopBar />
          <main>
            <Layout width={1200}>
              <Routes>
                <Route path="/" element={<CampaignCodeGenerator />} />
                <Route path="/utm-wiki" element={<UtmWiki />} />
                {blogPosts.map(
                  ({ slug, title, category, excerpt, markdown }) => (
                    <Route
                      key={slug}
                      path={`/utm-wiki/${slug}`}
                      element={
                        <BlogTemplate
                          title={title}
                          category={category}
                          excerpt={excerpt}
                        >
                          {markdown}
                        </BlogTemplate>
                      }
                    />
                  ),
                )}
              </Routes>
            </Layout>
          </main>
          <BottomBar />
        </BrowserRouter>
      </DataContext.Provider>
    )
  }

  return <div>Loading...</div>
}
