import { createContext } from 'react'
import { BlogItem } from '../api/modulariseAPI'

export const DataContext = createContext<{
  data: BlogItem[]
  error: string
}>({
  data: [],
  error: '',
})

// function DataProvider({ children }: { children: ReactNode }) {
//   const [data, setData] = useState<BlogItem[]>([])
//   const [error, setError] = useState('')

//   useEffect(() => {
//     const fetchData = async () => {
//       const apiKey = process.env.REACT_APP_API_KEY
//       const headers = {
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//       }

//       const options = {
//         method: 'GET',
//         url: 'https://api.flotiq.com/api/v1/content/blogpost',
//         headers: {
//           ...headers,
//           'X-AUTH-TOKEN': apiKey,
//         },
//       }

//       try {
//         const res = await axios.request<ResponseData>(options)
//         setData(res.data.data)
//       } catch (err: any) {
//         setError(err.response.data.message)
//       }
//     }

//     fetchData()
//   }, [])

//   const value = useMemo(() => ({ data, error }), [data, error])

//   return <DataContext.Provider value={value}>{children}</DataContext.Provider>
// }

// export default DataProvider
