import axios from 'axios'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../context/data-context'
import { shuffle } from '../utils'

interface WikiNavBoxProps {
  blogLabel: string
  blogTitle: string
}

function WikiNavBox({ blogLabel, blogTitle }: WikiNavBoxProps) {
  const { data, error } = useContext(DataContext)

  // returns array of links to blog articles that match the label of the rendered blog article, excluding the current rendered blog article (as we don't want a link to the current article as a suggested link)
  const blogLinks = useMemo(
    () =>
      data
        .filter(({ title }) => blogTitle !== title)
        .filter(({ category }) => blogLabel === category)
        .map(({ title, slug }) => (
          <Link to={`/utm-wiki/${slug}`} key={title}>
            {title}
          </Link>
        )),
    [data],
  )

  // links are shuffled so it's not always the same links in the same order that's shown
  const shuffledBlogLinks = useMemo(() => shuffle(blogLinks), [blogLinks])

  // maximum of 4 links are shown at any one time
  const shortenedBlogLinks = useMemo(
    () => shuffledBlogLinks.slice(0, 4),
    [shuffledBlogLinks],
  )

  if (error) {
    return <div>An error occurred</div>
  }

  if (data) {
    return (
      <div>
        <h2>Related articles</h2>
        <ul>
          {shortenedBlogLinks.map((link) => (
            <li>{link}</li>
          ))}
        </ul>
      </div>
    )
  }

  return <div>Loading...</div>
}

export default WikiNavBox
