import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import classNames from 'classnames'
import ToggleButton from 'react-toggle-button'

import Button from './button'
import { InputRow } from './campaign-code-generator-rows'
import { Radio } from './inputs'
import Label from './labels'
import { WarningModal } from './modal'
import { FieldSlot, LabelSlot, FormRow } from './row'
import ToolTip from './tooltip'
import { InnerBox, OuterBox } from './two-columns'
import { messages } from '../core/variables'
import {
  generatedStructure,
  queryLength,
  generatorTypes,
  urlLength,
} from '../core/generated-structure'
import styles from '../styles/campaign-code-generator.module.scss'
import { allDataOK, getValuesArray, isValidUrl } from '../utils'
import generateCode from '../utils/generate-code'
import gaEvent, { getLinkDomain } from '../utils/tracking'

interface CodeGeneratorSingleProps {
  setGeneratedCode: Dispatch<SetStateAction<string>>
  codes: GeneratedCode[]
  setCodes: Dispatch<SetStateAction<GeneratedCode[]>>
}

const CodeGeneratorSingle = React.forwardRef<
  HTMLFormElement,
  CodeGeneratorSingleProps
>(({ setGeneratedCode, codes, setCodes }, contentRef) => {
  const [currentTab, setCurrentTab] = useState(0)

  const [warningModalType, setWarningModalType] = useState<WarningTypes>('')
  const [showWarningModal, setShowWarningModal] = useState(false)

  const [linkType, setLinkType] = useState('full')

  const [values, setValues] = useState<CampaignCodeGeneratorValues>({})

  useEffect(() => {
    setValues(getValuesArray(generatorTypes[currentTab]))
  }, [currentTab])

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [errors, setErrors] = useState([false])
  const [submitError, setSubmitError] = useState(false)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [codeCreatedTimeout, setCodeCreatedTimeout] = useState(false)
  const [previewCode, setShowPreviewCode] = useState<GeneratedCode>(
    {} as GeneratedCode,
  )
  const [showCollapsible, setShowCollapsible] = useState(false)

  const inlineErrorsPresent = errors.filter((item) => item).length !== 0
  const softDisable = inlineErrorsPresent || submitDisabled

  const updateForm = (fieldID: string, value: string): void => {
    setIsDuplicate(false)

    setValues((currVals) => {
      const newVals = JSON.parse(JSON.stringify(currVals))

      newVals[fieldID] = {
        ...newVals[fieldID],
        value,
      }

      const disabled = !allDataOK(newVals, generatorTypes[currentTab])
      setSubmitDisabled(disabled)

      return newVals
    })
  }

  const actionAddCode = (code: GeneratedCode) => {
    setCodeCreatedTimeout(true)

    setGeneratedCode(code.fullUrl)

    setCodes((currCodes) => {
      const updatedCodes = JSON.parse(JSON.stringify(currCodes))
      updatedCodes.unshift(code)

      return updatedCodes
    })
    const lsCodes = window.localStorage.getItem('prevCodes')

    const newPrevCodes =
      lsCodes === null ? [] : (JSON.parse(lsCodes) as GeneratedCode[])

    newPrevCodes.unshift(code)
    window.localStorage.setItem('prevCodes', JSON.stringify(newPrevCodes))

    window.setTimeout(() => {
      setCodeCreatedTimeout(false)
    }, 800)

    gaEvent('code_created', {
      code_type: 'Web',
      code_full: code.fullUrl,
      code_domain: getLinkDomain(code.link),
      code_source: code.params.source.value,
      code_medium: code.params.medium.value,
      code_campaign: code.params.campaign.value,
      code_content: code.params.content.value,
      code_term: code.params.term.value,
      code_source_platform: code.params.source_platform.value,
      code_creative_format: code.params.creative_format.value,
      code_marketing_tactic: code.params.marketing_tactic.value,
    })
  }

  const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()
    setIsDuplicate(false)

    if (codeCreatedTimeout) return

    if (softDisable) {
      setSubmitError(true)
      return
    }

    setSubmitError(false)

    const code = generateCode(values, generatorTypes[currentTab])

    const urlValidLength = code.fullUrl.length <= urlLength
    const queryValidLength = code.code.length <= queryLength
    const hasAnchor = code.link.indexOf('#') !== -1
    const isValid = isValidUrl(code.link)

    setShowPreviewCode(code)

    const isDuplicateUrl = codes.findIndex(
      (existingCode) => code.fullUrl === existingCode.fullUrl,
    )

    if (isDuplicateUrl > -1) {
      setIsDuplicate(true)
      return
    }

    if (!hasAnchor && isValid && urlValidLength && queryValidLength) {
      actionAddCode(code)
    } else {
      setShowWarningModal(true)
      if (hasAnchor) {
        setWarningModalType('has-anchor')
      } else if (!queryValidLength) {
        setWarningModalType('invalid-query-length')
      } else if (!urlValidLength) {
        setWarningModalType('invalid-length')
      } else {
        setWarningModalType('invalid-url')
      }
    }
  }

  useEffect(() => {
    if (showWarningModal) {
      let desc = {}
      if (
        warningModalType === 'invalid-query-length' ||
        warningModalType === 'invalid-length'
      ) {
        const maxLength =
          warningModalType === 'invalid-length' ? urlLength : queryLength

        const length =
          warningModalType === 'invalid-length'
            ? previewCode.fullUrl.length
            : previewCode.fullUrl.replace(previewCode.link, '').length
        desc = {
          maxLength,
          length,
        }
      }
    }
  }, [showWarningModal, warningModalType])

  return (
    <>
      <OuterBox className={styles.outerBox}>
        <InnerBox className={styles.innerBox}>
          <form className={styles.form} onSubmit={onSubmit} ref={contentRef}>
            <InputRow
              name="Landing page URL"
              tooltip="The web page your users will land on once they click on your campaign link."
              id="landing-page"
              className={classNames(styles.rowWrapper, styles.rowWrapperUrl)}
              updateForm={(value: string): void =>
                updateForm('landing-page', value)
              }
              onValidation={(valid: boolean) => {
                const newError = errors.slice()
                newError[0] = !valid
                setErrors(newError)
              }}
            />
            {generatedStructure[generatorTypes[currentTab]]
              ?.filter((field) => !field.collapsible)
              ?.map(
                (field: GeneratorField, index: number): React.ReactElement => {
                  const wrapperClassName = classNames(styles.rowWrapper)

                  return (
                    <InputRow
                      key={field.fieldID}
                      name={field.fieldName}
                      id={field.fieldID}
                      tooltip={field.helpText || ''}
                      className={wrapperClassName}
                      updateForm={(value: string): void => {
                        updateForm(field.fieldID, value)
                      }}
                      onValidation={(valid: boolean) => {
                        const newError = errors.slice()
                        newError[index + 1] = !valid
                        setErrors(newError)
                      }}
                      optional={!field.required}
                    />
                  )
                },
              )}
            <FormRow className={styles.collapsibleToggle}>
              <div className={styles.formLabel}>
                <Label id="showCollapsible">GA4 parameters</Label>
                <ToolTip
                  showOnRight
                  className={styles.tooltip}
                  message={
                    <p>
                      GA4 introduced new parameters for campaign tracking. You
                      can view more details about them{' '}
                      <a
                        href="https://support.google.com/analytics/answer/10917952?hl=en"
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </a>
                      .
                    </p>
                  }
                />
              </div>
              <ToggleButton
                onToggle={() => {
                  setShowCollapsible((curr) => !curr)

                  gaEvent('ga4_toggle')
                }}
                value={showCollapsible}
                colors={{
                  active: {
                    base: '#007d9b',
                    hover: '#007d9b',
                  },
                  inactive: {
                    base: '#F7FAFC',
                    hover: '#F7FAFC',
                  },
                  inactiveThumb: {
                    base: '#007d9b',
                    hover: '#007d9b',
                  },
                }}
                trackStyle={{ border: '1px solid #cbd5e0' }}
                inactiveLabelStyle={{ color: '#000' }}
                id="showCollapsible"
                inactiveLabel="NO"
                activeLabel="YES"
              />
            </FormRow>
            {showCollapsible &&
              generatedStructure[generatorTypes[currentTab]]
                ?.filter((field) => field.collapsible)
                ?.map(
                  (
                    field: GeneratorField,
                    index: number,
                  ): React.ReactElement => {
                    const wrapperClassName = classNames(styles.rowWrapper)

                    return (
                      <InputRow
                        key={field.fieldID}
                        name={field.fieldName}
                        id={field.fieldID}
                        tooltip={field.helpText || ''}
                        className={wrapperClassName}
                        updateForm={(value: string): void => {
                          updateForm(field.fieldID, value)
                        }}
                        onValidation={(valid: boolean) => {
                          const newError = errors.slice()
                          newError[index + 1] = !valid
                          setErrors(newError)
                        }}
                        optional={!field.required}
                      />
                    )
                  },
                )}
            <FormRow>
              <LabelSlot className={styles.labelSlot}>
                <Label id="link-type">Link type</Label>
                <ToolTip
                  className={styles.tooltip}
                  showOnRight
                  message={messages.linkTypes}
                />
              </LabelSlot>
              <FieldSlot>
                <div className={styles.inlineWrap}>
                  <div className={styles.radioPanel}>
                    <Radio
                      checked={linkType === 'full'}
                      id="link-type-2"
                      name="link-type"
                      label="Full link"
                      onChange={() => setLinkType('full')}
                    />
                    <Radio
                      checked={linkType === 'short'}
                      id="link-type-1"
                      name="link-type"
                      label="Short link"
                      onChange={() => {
                        gaEvent('short_link_clicked')

                        setLinkType('short')
                      }}
                    />
                  </div>
                </div>
                {linkType === 'full' ? (
                  <Button
                    disabled={softDisable || codeCreatedTimeout}
                    submit
                    style={{ margin: '8px 0 0 0' }}
                    className={`${
                      softDisable || codeCreatedTimeout
                        ? styles.softDisableButton
                        : ''
                    }`}
                    type="container"
                  >
                    Create campaign link
                  </Button>
                ) : (
                  <Button
                    type="container"
                    href="https://upl.inc/shortlinkaccount"
                    newTab
                    style={{ margin: '8px 0 0 0' }}
                  >
                    Create a free account
                  </Button>
                )}
                {submitError && (
                  <p className={styles.footNoteError}>
                    Please have a valid value for every required parameter.
                  </p>
                )}
                {isDuplicate && (
                  <p className={styles.footNoteError}>
                    Already created - check your recently created links.
                  </p>
                )}
              </FieldSlot>
            </FormRow>
          </form>
        </InnerBox>
      </OuterBox>
      {showWarningModal && (
        <WarningModal
          url={previewCode.fullUrl}
          type={warningModalType}
          maxLength={
            warningModalType === 'invalid-length' ? urlLength : queryLength
          }
          length={
            warningModalType === 'invalid-length'
              ? previewCode.fullUrl.length
              : previewCode.fullUrl.replace(previewCode.link, '').length
          }
          onYes={() => {
            setShowWarningModal(false)
            actionAddCode(previewCode)
          }}
          onNo={() => {
            setShowWarningModal(false)
          }}
        />
      )}
    </>
  )
})

export default CodeGeneratorSingle
