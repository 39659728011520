import {
  useState,
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  useRef,
} from 'react'
import classNames from 'classnames'

import Button from './button'
import Label from './labels'
import styles from '../styles/inputs.module.scss'
import { makeLinkSecure, outerSpanClick } from '../utils'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string
  name?: string
  className?: string
  label: string | ReactNode
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (event: React.MouseEvent) => void
  prefix?: string
}

export function Checkbox({
  id,
  name,
  className,
  label = '',
  disabled = false,
  checked = false,
  onChange,
  onClick,
  prefix,
}: CheckboxProps) {
  const inputClassNames = classNames(className, styles.checkboxContainer, {
    [styles.disabled]: disabled,
  })

  const inputElement = (
    <span className={styles.inputWrapper}>
      {prefix && <em className={styles.prefix}>{prefix}</em>}
      <input
        type="checkbox"
        id={id}
        name={name}
        className={styles.input}
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          }
        }}
        checked={checked}
        disabled={disabled}
      />
      <b />
      <span className={styles.labelText}>{label}</span>
    </span>
  )

  if (label === '') {
    return (
      <span
        className={inputClassNames}
        role="button"
        tabIndex={0}
        onClick={outerSpanClick}
        onKeyDown={outerSpanClick}
      >
        {inputElement}
      </span>
    )
  }
  return (
    <Label
      id={id || name}
      className={inputClassNames}
      onClick={(e) => {
        if (onClick) {
          onClick(e)
        }
      }}
    >
      {inputElement}
    </Label>
  )
}

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string
  name?: string
  className?: string
  label: string | ReactNode
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (event: React.MouseEvent) => void
}

export function Radio({
  id,
  name,
  className,
  label = '',
  disabled = false,
  checked = false,
  onChange,
  onClick,
}: RadioProps) {
  const inputClassNames = classNames(className, styles.checkboxContainer, {
    [styles.disabled]: disabled,
  })

  const inputElement = (
    <span className={styles.inputWrapper}>
      <input
        type="radio"
        id={id}
        name={name}
        className={styles.input}
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          }
        }}
        checked={checked}
        disabled={disabled}
      />
      <em className={styles.radioControl} />
      <span className={styles.labelText}>{label}</span>
    </span>
  )

  return label === '' ? (
    <span
      className={inputClassNames}
      role="button"
      tabIndex={0}
      onClick={outerSpanClick}
      onKeyDown={outerSpanClick}
    >
      {inputElement}
    </span>
  ) : (
    <Label
      id={id || name}
      className={inputClassNames}
      onClick={(e) => {
        if (onClick) {
          onClick(e)
        }
      }}
    >
      {inputElement}
    </Label>
  )
}

interface PassiveInputRowProps {
  id: string
  value: string
  row?: boolean
  copy?: boolean
}

export function PassiveInput({
  id,
  value,
  row,
}: PassiveInputRowProps): React.ReactElement {
  const passiveClassNames = classNames(styles.input, {
    [styles.rowInput]: row,
  })
  return (
    <span className={styles.passiveContainer}>
      <input
        type="text"
        className={passiveClassNames}
        id={id}
        name={id}
        value={value}
        readOnly
      />
    </span>
  )
}

interface Props extends React.InputHTMLAttributes<any> {
  id?: string
  name: string
  label?: string | React.ReactNode
  required?: boolean
  placeholder?: string
  updateUrl?: any
  onClick?: (event: React.MouseEvent) => void
  onValueChange?: (value: string) => void
  beforeChange?: (value: string) => string
  onChange?: any
  error?: string
  className?: string
  disabled?: boolean
  showClear?: boolean
  prefix?: string
  multilineInput?: boolean
  textAreaHeight?: number | null
  maxLength?: number
  lineBreakAll?: boolean
  delay?: number
  onKeyDown?: any
  onKeyUp?: any
}

export default function Input({
  id,
  name,
  label = '',
  value,
  required,
  type,
  placeholder,
  onChange,
  onValueChange,
  beforeChange,
  updateUrl,
  onFocus,
  onClick,
  error,
  className,
  disabled,
  showClear,
  prefix,
  checked,
  multilineInput = false,
  textAreaHeight = null,
  maxLength,
  lineBreakAll = true,
  delay,
  onKeyDown,
  onKeyUp,
  autoCapitalize,
}: Props): React.ReactElement {
  const [inputValue, setInputValue] = useState(value)
  const [timeoutHandle, setTimeoutHandle] = useState<null | number>(null)

  const rest = {
    type,
    onFocus,
    required,
    disabled,
    autoCapitalize,
  }

  const inputClassNames = classNames(className, styles.container, {
    [styles.error]: error,
    [styles.disabled]: disabled,
  })

  const inputRef = useRef(inputValue)

  const isEmptyValue =
    inputValue === '' ||
    inputValue === null ||
    (Array.isArray(inputValue) &&
      inputValue.length === 1 &&
      inputValue[0] === '')

  const inputElement =
    type === 'textArea' ? (
      <textarea
        style={{ height: textAreaHeight ? `${textAreaHeight}px` : 'inherit' }}
        maxLength={maxLength || undefined}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>): void => {
          const { value: val } = event.target
          const targetInputValue = beforeChange ? beforeChange(val) : val
          setInputValue(targetInputValue)
          if (onChange) {
            if (!delay) {
              onChange(val)
            } else {
              const timeout = window.setTimeout(() => {
                onChange(inputRef.current)
              }, delay)
              setTimeoutHandle(timeout)
            }
          }
          if (onValueChange) {
            onValueChange(
              typeof targetInputValue === 'string' ? targetInputValue : '',
            )
          }
        }}
        placeholder={placeholder || (typeof label === 'string' ? label : '')}
        id={id || name}
        name={name}
        className={classNames(styles.textArea, {
          [styles.smallTextArea]: multilineInput,
          [styles.wordBreak]: lineBreakAll,
        })}
        value={inputValue}
        {...rest}
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e)
          }
          if (timeoutHandle) {
            window.clearTimeout(timeoutHandle)
          }
        }}
        onClick={(e) => {
          if (onClick && label === '') {
            onClick(e)
          }
        }}
        onKeyUp={(e) => {
          if (multilineInput && onKeyUp) {
            onKeyUp(e)
          }
        }}
      />
    ) : (
      <span className={styles.inputWrapper}>
        {prefix && <em className={styles.prefix}>{prefix}</em>}
        <input
          checked={checked}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            const { value: val } = event.target

            const targetInputValue = beforeChange ? beforeChange(val) : val
            setInputValue(targetInputValue)

            if (onChange) {
              onChange(event)
            }
            if (onValueChange) {
              onValueChange(
                typeof targetInputValue === 'string' ? targetInputValue : '',
              )
            }
          }}
          placeholder={placeholder || (typeof label === 'string' ? label : '')}
          id={id || name}
          name={name}
          className={styles.input}
          value={inputValue}
          onClick={(e) => {
            if (onClick && label === '') {
              onClick(e)
            }
          }}
          onBlur={(event: ChangeEvent<HTMLInputElement>) => {
            if (updateUrl) {
              const { value: val } = event.target
              const validUrl = makeLinkSecure(val)

              if (validUrl !== val) {
                setInputValue(validUrl)
                updateUrl(validUrl)
              }
            }
          }}
          {...rest}
        />

        {showClear && !isEmptyValue && (
          <Button
            type="clear"
            className={styles.clearButton}
            onClick={(event: React.MouseEvent) => {
              setInputValue('')
              if (onChange) {
                onChange(event)
              }
              if (onValueChange) {
                onValueChange('')
              }
            }}
            ariaLabel="cancel"
          />
        )}
      </span>
    )

  if (label === '') {
    return (
      <span
        className={inputClassNames}
        role="button"
        tabIndex={0}
        onClick={outerSpanClick}
        onKeyDown={outerSpanClick}
      >
        {inputElement}
      </span>
    )
  }
  return (
    <Label
      id={id || name}
      className={inputClassNames}
      onClick={(e) => {
        if (onClick) {
          onClick(e)
        }
      }}
    >
      {inputElement}
    </Label>
  )
}
