import Button from './button'

export default function UplifterInfo(): React.ReactElement {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <h2>Want to create free UTM links?</h2>
        <Button type="text" align="left" href="/">
          Create links in UTM Dog
        </Button>
      </div>
      <br />

      <h2>Need more than a few UTM links?</h2>

      <Button
        href="https://upl.inc/WM9Fmf "
        newTab
        style={{ marginBottom: 0 }}
        type="text"
        id="moreInfo"
      >
        Learn about Uplifter
      </Button>
    </div>
  )
}
