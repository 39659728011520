import React, { useState, useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import numeral from 'numeral'

import styles from '../styles/modal.module.scss'
import ConfirmationButtons from './confirmation-buttons'

interface MProps {
  children?: React.ReactNode
  active?: boolean
  toggleActive: () => void
  className?: string
  narrow?: boolean
  white?: boolean
  isWarning?: boolean
}

export default function Modal({
  children,
  active = false,
  toggleActive,
  className = '',
  narrow = false,
  white = false,
  isWarning = false,
}: MProps): React.ReactElement | null {
  const [transitionActive, setTransition] = useState(active)

  useEffect((): void => {
    if (!active) {
      setTimeout((): void => setTransition(active), 500)
    } else {
      setTransition(active)
    }
  }, [active])

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        toggleActive()
      }
    }
    if (transitionActive) {
      document.addEventListener('keydown', onKeyDown)
    } else {
      document.removeEventListener('keydown', onKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [transitionActive])

  const root = document.getElementById('modals')

  if (!root) return null

  const modalClassName = classNames(styles.modal, styles.whiteModal, {
    [styles.narrowModal]: narrow,
    [styles.whiteModal]: white,
    [styles.warningModal]: isWarning,
  })

  const modalWrapperClassName = classNames({
    [styles.darkBox]: active,
    [styles.hideBox]: !active,
  })

  return createPortal(
    <div className={modalWrapperClassName} aria-label="modal">
      <style>
        {transitionActive
          ? `
            #root, body {
              overflow: hidden !important;
            }
          `
          : ''}
      </style>
      <section className={modalClassName}>
        <div className={`${styles.inner} ${className}`}>
          <a
            className={styles.close}
            onClick={(e) => {
              e.preventDefault()
              toggleActive()
            }}
            href="#close"
          >
            Close
          </a>
          {children}
        </div>
      </section>
    </div>,
    root,
  )
}

interface Props {
  children?: React.ReactNode
  style?: React.CSSProperties
}

export function ModalHead({ children, style }: Props): React.ReactElement {
  return (
    <div className={styles.head} style={style}>
      {children}
    </div>
  )
}

interface PropsModalBody {
  children?: React.ReactNode
  className?: string
}

export function ModalBody({
  children,
  className,
}: PropsModalBody): React.ReactElement {
  return <div className={classNames(className, styles.body)}>{children}</div>
}

export function ModalFooter({ children }: Props): React.ReactElement {
  return <div className={styles.footer}>{children}</div>
}

interface WarningModalProps {
  onYes: () => void
  onNo: () => void
  type: WarningTypes
  length?: number
  maxLength?: number
  url: string
}

export function WarningModal({
  onYes,
  onNo,
  type,
  length = 0,
  maxLength = 0,
  url,
}: WarningModalProps): React.ReactElement {
  const noLabel = useMemo(() => {
    switch (type) {
      case 'invalid-length':
        return 'Back and edit landing page URL'
      case 'invalid-query-length':
        return 'Back and edit parameters'
      case 'has-anchor':
      case 'invalid-url':
        return 'Back and update landing page'
      default:
        return ''
    }
  }, [type])

  const showCreateButton = useMemo(
    () => !(type === 'invalid-length' || type === 'invalid-query-length'),
    [type],
  )

  return (
    <Modal
      active
      toggleActive={() => {
        onNo()
      }}
      isWarning
    >
      <ModalHead>
        <h3 className={styles.heading}>
          {(() => {
            switch (type) {
              case 'has-anchor':
                return 'This link has a page anchor'
              case 'invalid-url':
                return 'Not a valid URL'
              default:
                return 'This link is too long'
            }
          })()}
        </h3>
      </ModalHead>
      <ModalBody className={styles.modalBody}>
        {type === 'has-anchor' && (
          <>
            <p>
              Your link contains a page anchor, '#', which will be moved to the
              end to make this a valid link.
            </p>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.uplifter.ai/hc/en-us/articles/4469479289873-Can-I-use-anchor-tags-or-in-my-landing-page-URL-with-query-string-parameters-utms-"
              >
                {' '}
                Find out more.
              </a>
            </p>
          </>
        )}
        {(type === 'invalid-length' || type === 'invalid-query-length') && (
          <>
            <p>
              The{' '}
              {type === 'invalid-length' ? 'resulting link' : 'query string'}{' '}
              will be too long to work in most web browsers or marketing
              platforms.
            </p>
            <p>
              Your{' '}
              {type === 'invalid-length' ? 'resulting link' : 'query string'} is{' '}
              <strong>
                {numeral(length - maxLength).format('0,0')} characters longer
              </strong>{' '}
              than the maxium recommended ({numeral(maxLength).format('0,0')}{' '}
              characters).
            </p>
            <p>
              {type === 'invalid-length' ? (
                <>
                  Try <strong>changing the landing page</strong> to a shorter
                  url.
                </>
              ) : (
                <>
                  Type{' '}
                  <strong>fewer characters into the parameter values</strong>{' '}
                  for a shorter string.
                </>
              )}
            </p>
            <p>
              For help email{' '}
              <a href="mailto:support@uplifter.ai">support@uplifter.ai</a>
            </p>
          </>
        )}
        {type === 'invalid-url' && (
          <p>
            The landing page you provided is not a valid URL. Do you still want
            to create this link?
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <ConfirmationButtons
          disabled={!showCreateButton}
          hideDisabled={!showCreateButton}
          type={type === 'has-anchor' ? 'simple' : 'warning'}
          onYes={() => {
            onYes()
          }}
          onNo={(): void => {
            onNo()
          }}
          yesLabel="Yes, create"
          noLabel={noLabel}
        />
      </ModalFooter>
    </Modal>
  )
}
