import { useEffect, useState } from 'react'
import shortid from 'shortid'

import styles from '../styles/color-picker.module.scss'
import { presetColors } from '../utils/qr-code'
import Button from './button'
import Input from './inputs'

interface ColourPickerProps {
  color: string
  setColor: (val: string) => void
  defaultColour: string
}

export function ColourPicker({
  color,
  setColor,
  defaultColour,
}: ColourPickerProps): React.ReactElement {
  const [key, setKey] = useState(shortid.generate())
  const useColours = Array.from(
    new Set([defaultColour, ...presetColors]),
  ).slice(0, 4)

  useEffect(() => {
    setKey(shortid.generate())
  }, [useColours.length])

  return (
    <div className={styles.swatchContainerWrapper}>
      <div className={styles.swatchContainer}>
        {useColours.map((presetColor: string) => (
          <Button
            key={presetColor}
            type="swatch"
            color={presetColor}
            onClick={() => setColor(presetColor)}
            style={{
              backgroundColor: presetColor,
              border: `${
                presetColor === '#FFFFFF' ? '1px solid black' : 'none'
              }`,
            }}
          />
        ))}
      </div>
      <div className={styles.colorField}>
        <Input
          key={key}
          name="color"
          id="color"
          value={color}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const { value: val } = event.target
            setColor(val)
          }}
        />
      </div>
    </div>
  )
}
