import FileSaver from 'file-saver'
import moment from 'moment'

interface Size {
  label: string
  size: string
  value: string
  qrSize: number
}

export const sizes: Size[] = [
  {
    label: 'Small (600 x 600 px)',
    size: '600',
    value: '300',
    qrSize: 70,
  },
  {
    label: 'Medium (1000 x 1000 px)',
    size: '1000',
    value: '500',
    qrSize: 150,
  },
  {
    label: 'Large (2000 x 2000 px)',
    size: '2000',
    value: '1000',
    qrSize: 300,
  },
]

export const presetColors = [
  '#FFFFFF',
  '#000000',
  '#A0AEC0',
  '#E61969',
  '#0FC3F0',
  '#007D9B',
  '#FFD70F',
]

export const downloadQRCode = async (blob: Blob, type = 'png', code = '') => {
  if (typeof blob !== 'undefined') {
    const now = new Date(Date.now())
    const fileName = code
      .replace(/(https:\/\/www.)|(http:\/\/)|(https:\/\/)/gi, '')
      .slice(0, 20)
    await FileSaver.saveAs(
      blob,
      `${moment(now).format('YYYY-MM-DD')} QR to ${fileName}.${type}`,
    )
  }

  return true
}
