import FileSaver from 'file-saver'
import moment from 'moment'

import { generatedStructure } from '../core/generated-structure'

export function getCsvHeader(formTypes: Platforms[]) {
  const result: string[] = []

  if (formTypes.length > 1) result.push('Code type')

  result.push('Timestamp')
  result.push('Full campaign URL')
  result.push('Landing page')

  formTypes.forEach((type) => {
    generatedStructure[type]?.forEach(({ fieldName, prefix }) => {
      if (result.indexOf(`${fieldName} (${prefix})`) === -1)
        result.push(`${fieldName} (${prefix})`)
    })
  })

  return result
}

export function getCsvRows(codes: GeneratedCode[], headers: string[]) {
  const result: string[][] = Array.from(new Array(codes.length), (_) => [])

  codes.forEach((code, index) => {
    const row: string[] = []

    headers.forEach((header) => {
      switch (header) {
        case 'Code type':
          row.push(code.codeType)
          break
        case 'Timestamp':
          row.push(moment(code.created).format('YYYY-MM-DD hh:mm:ss.SSSSZ'))
          break
        case 'Full campaign URL':
          row.push(code.fullUrl)
          break
        case 'Landing page':
          row.push(code.link)
          break
        default:
          row.push(
            Object.values(code.params).find(
              ({ fieldName, prefix }) => `${fieldName} (${prefix})` === header,
            )?.value || '',
          )
          break
      }
    })

    result[index].push(...row.map((r) => `"${r.replace(/\"/gi, '""')}"`))
  })

  return result
}

export function getCsvString(
  selectedCodes: string[],
  codes: GeneratedCode[],
): string {
  const csvRows: string[] = []

  const formTypes = Array.from(new Set(codes.map(({ codeType }) => codeType)))
  const filteredCodes = codes.filter(({ id }) => selectedCodes.indexOf(id) > -1)

  const headers = getCsvHeader(formTypes)
  csvRows.push(headers.map((r) => `"${r.replace(/\"/gi, '""')}"`).join(','))

  const rows = getCsvRows(filteredCodes, headers)

  rows.reverse().forEach((row) => {
    csvRows.push(row.join(','))
  })

  return csvRows.join('\n')
}

export const downloadCodes = async (data: string): Promise<any> => {
  if (typeof data === 'string') {
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
    const now = new Date(Date.now())
    await FileSaver.saveAs(
      blob,
      `${moment(now).format('YYYY-MM-DD')} UTM Dog Campaign Codes.csv`,
    )
  }

  return true
}
