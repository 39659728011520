import classNames from 'classnames'

import styles from '../styles/loader.module.scss'

interface PreloaderProps {
  className?: string
  style?: React.CSSProperties
}

export function Preloader({
  className,
  style,
}: PreloaderProps): React.ReactElement {
  return (
    <div className={classNames(styles.gifContainer, className)} style={style}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
