import axios from 'axios'

export interface BlogItem {
  content: any
  excerpt: string
  slug: string
  title: string
  markdown: string
  id: string
  author: string
  reading_time: string
  headerImage: any[]
  category: string
  internal: {
    createdAt: string
    deletedAt: string
    updatedAt: string
    contentType: string
    objectTitle: string
    workflow_state: string
  }
}

export interface ResponseData {
  count: number
  current_page: number
  total_count: number
  total_pages: number
  data: BlogItem[]
}

export const fetchData = async () => {
  const apiKey = process.env.REACT_APP_FLOTIQ_API_READONLY
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  const options = {
    method: 'GET',
    url: 'https://api.flotiq.com/api/v1/content/blogpost?limit=50',
    headers: {
      ...headers,
      'X-AUTH-TOKEN': apiKey,
    },
  }

  return axios
    .request<ResponseData>(options)
    .then((res) => res.data.data)
    .catch((err) => Promise.reject(err.response.data.message))
}
