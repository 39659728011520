import classNames from 'classnames'

import styles from '../styles/two-columns.module.scss'

interface PropsColumn {
  children?: React.ReactNode
  main?: boolean
  side?: boolean
  fixed?: boolean
  equal?: boolean
  transparent?: boolean
  className?: string
  fwdRef?: any
  style?: any
}

export function Column({
  children,
  main,
  side,
  fixed,
  equal,
  transparent,
  className,
  fwdRef = null,
  style = null,
}: PropsColumn) {
  const defaultClassName = classNames(className, styles.columnWrapper, {
    [styles.mainColumn]: main,
    [styles.sideColumn]: side,
    [styles.fixedColumn]: fixed,
    [styles.equalColumn]: equal,
    [styles.transparent]: transparent,
  })
  return (
    <div ref={fwdRef} style={style} className={defaultClassName}>
      {children}
    </div>
  )
}

interface InnerBoxProps {
  children?: React.ReactNode
  className?: string
  showDivider?: boolean
  noPadding?: boolean
}

export function InnerBox({
  children,
  className,
  showDivider,
  noPadding,
}: InnerBoxProps) {
  const defaultClassName = classNames(className, styles.innerBox, {
    [styles.divider]: showDivider,
    [styles.noPadding]: noPadding,
  })
  return <div className={defaultClassName}>{children}</div>
}

interface Props {
  children?: React.ReactNode
  className?: string
  panel?: boolean
}

export function OuterBox({ children, className, panel }: Props) {
  const defaultClassName = classNames(className, styles.box, {
    [styles.panel]: panel,
  })
  return <div className={defaultClassName}>{children}</div>
}

export default function TwoColumns({ children }: Props) {
  return <div className={styles.wrapper}>{children}</div>
}
