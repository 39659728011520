import Layout from './layout'
import styles from '../styles/bottom-bar.module.scss'

export default function BottomBar(): React.ReactElement {
  return (
    <footer className={styles.footerContainer}>
      <Layout width={1200}>
        <p>
          Support: <a href="mailto:support@uplifter.ai">support@uplifter.ai</a>
        </p>
        <p>
          © {new Date().getFullYear()}
          {' - '}
          {'Uplifter Ltd. '}
        </p>
      </Layout>
    </footer>
  )
}
