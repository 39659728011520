import React, {
  useRef,
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import classNames from 'classnames'
import shortid from 'shortid'
import QRCode from 'qrcode.react'

import { ColourPicker } from './color-picker'
import ConfirmationButtons from './confirmation-buttons'
import { Checkbox, PassiveInput, Radio } from './inputs'
import Modal, { ModalHead, ModalBody, ModalFooter } from './modal'
import { QRCodeImage } from './qr-code-image'
import TwoColumns, { Column } from './two-columns'
import styles from '../styles/qr-code.module.scss'
import { downloadQRCode, sizes } from '../utils/qr-code'
import { ImgUploader } from './img-uploader'
import Label from './labels'
import Button from './button'

interface Props {
  imageFile: string
  setImageFile: Dispatch<SetStateAction<string>>
  setShowModal?: (status: boolean) => void
  code?: string
  returnQrCodeOnly?: boolean
  disableCopy?: boolean
  children?: any
}

export default function CreateQrModal({
  imageFile,
  setImageFile,
  setShowModal,
  code = '',
  returnQrCodeOnly = false,
  disableCopy = false,
  children,
}: Props): React.ReactElement {
  const [loaded, setLoaded] = useState(false)
  const [showLogo, setShowLogo] = useState(true)
  const [selectedValue, setSelectedValue] = useState('300')
  const [selectedType, setSelectedType] = useState('png')
  const defaultFgColour = '#000000'
  const [fgColour, setFgColour] = useState(defaultFgColour)
  const defaultBgColour = '#FFFFFF'
  const [bgColour, setBgColour] = useState(defaultBgColour)
  // const defaultQrStyle = 'squares'
  // const [qrStyle, setQrStyle] = useState<'dots' | 'squares'>(defaultQrStyle)
  const [qrTransparentLogo, setQrTransparentLogo] = useState(false)

  const ref = useRef<HTMLDivElement>(null)
  const canvas = useRef<HTMLCanvasElement>(null)

  const redrawCanvas = (useColour = '') => {
    if (canvas && canvas.current && imageFile !== '') {
      setLoaded(false)
      const context = canvas.current.getContext('2d')

      if (context) {
        context.clearRect(0, 0, 200, 200)
        const image = new Image()
        if (!qrTransparentLogo) {
          context.fillRect(0, 0, 200, 200)
          context.fillStyle = useColour !== '' ? useColour : bgColour
        }
        image.onload = () => {
          const imageHeight = (160 * image.height) / image.width
          context.clearRect(0, 0, 200, 200)

          context.drawImage(
            image,
            20,
            (200 - imageHeight) / 2,
            160,
            imageHeight,
          )
          setLoaded(true)
        }
        image.src = imageFile
      }
    }
  }

  useEffect(() => {
    redrawCanvas()
  }, [canvas, imageFile])

  // Simple hex colour validator
  const useFgColour = /^#([0-9A-F]{3}){1,2}$/i.test(fgColour)
    ? fgColour
    : defaultFgColour
  const useBgColour = /^#([0-9A-F]{3}){1,2}$/i.test(bgColour)
    ? bgColour
    : defaultBgColour

  const logoImage = useMemo(() => {
    if (showLogo && canvas && canvas.current && loaded) {
      return canvas.current.toDataURL('image/png', 1.0)
    }
    return ''
  }, [showLogo, canvas, loaded])

  const qrSize = useMemo(() => {
    const fileSize = sizes.find((size) => size.value === selectedValue)
    if (fileSize) return fileSize.qrSize
    return sizes[0].qrSize
  }, [sizes, selectedValue])

  if (returnQrCodeOnly)
    return (
      <QRCodeImage
        canvas={canvas}
        fgColor={fgColour}
        bgColor={bgColour}
        code={code}
        showLogo
        logoImage={logoImage}
        disableCopy={disableCopy}
        selectedType={selectedType}
      >
        {children}
      </QRCodeImage>
    )

  return (
    <Modal
      narrow
      active
      toggleActive={() => {
        if (setShowModal) {
          setShowModal(false)
        }
      }}
      white
    >
      <ModalHead>
        <h3>Download QR code</h3>
      </ModalHead>
      <ModalBody>
        {code.length > 1024 ? (
          <p>
            Your url is longer than maximum characters we can support (max 1024
            characters). Please use a short link.
          </p>
        ) : (
          <TwoColumns className={styles.columns}>
            <Column main transparent equal className={styles.mainColumn}>
              <Label modalHeading id="qr">
                Preview
              </Label>
              <div className={styles.mainColumnInner}>
                <QRCodeImage
                  canvas={canvas}
                  fgColor={fgColour}
                  bgColor={bgColour}
                  code={code}
                  showLogo={showLogo}
                  logoImage={logoImage}
                  disableCopy={disableCopy}
                  selectedType={selectedType}
                >
                  {children}
                </QRCodeImage>
                <div className={styles.hide} key={shortid.generate()}>
                  <div ref={ref}>
                    <QRCode
                      fgColor={useFgColour}
                      bgColor={useBgColour}
                      value={code}
                      level="H"
                      size={
                        selectedType === 'svg'
                          ? 600
                          : window.parseInt(selectedValue, 10)
                      }
                      renderAs={selectedType === 'svg' ? 'svg' : 'canvas'}
                      imageSettings={
                        showLogo
                          ? {
                              src: logoImage,
                              width: selectedType === 'svg' ? 180 : qrSize,
                              height: selectedType === 'svg' ? 180 : qrSize,
                              excavate: true,
                            }
                          : undefined
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className={classNames(styles.checkboxRow, styles.inlineTitle)}
              >
                <Checkbox
                  prefix="Logo"
                  id="showLogo"
                  name="showLogo"
                  checked={showLogo}
                  label=" "
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const { checked } = e.target as HTMLInputElement
                    setShowLogo(checked)
                  }}
                />
                {showLogo && (
                  <ImgUploader
                    uploadButtonText="Upload logo"
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                  />
                )}
              </div>

              {/* 
                // ! QR shape: Round or square
                <div className={styles.row}>
                  <Label modalHeading id="qrType">
                    Shape
                  </Label>
                  <div className={styles.alignRow}>
                    <Input
                      name="qrType"
                      id={shortid.generate()}
                      label="Squares"
                      type="radio"
                      value="squares"
                      checked={qrStyle !== 'dots'}
                      onClick={(e): any => {
                        e.preventDefault()
                        setQrStyle('squares')
                      }}
                    />
                    <Input
                      name="qrType"
                      id={shortid.generate()}
                      label="Dots"
                      type="radio"
                      value="dots"
                      checked={qrStyle === 'dots'}
                      onClick={(e): any => {
                        e.preventDefault()
                        setQrStyle('dots')
                      }}
                    />
                  </div>
                </div>
              */}

              <Label modalHeading id="code">
                Shape colour
              </Label>
              <ColourPicker
                defaultColour={defaultFgColour}
                setColor={(c) => {
                  setFgColour(c)
                }}
                color={fgColour}
              />
              <Label modalHeading id="code">
                Background colour
              </Label>
              <ColourPicker
                defaultColour={defaultBgColour}
                setColor={(c) => {
                  setBgColour(c)
                  redrawCanvas(c)
                }}
                color={bgColour}
              />
            </Column>
            <Column side transparent equal className={styles.sideColumn}>
              <div className={styles.row}>
                <Label modalHeading id="code">
                  Destination
                </Label>
                {code.length <= 140 && (
                  <p className={styles.passiveInput}>{code}</p>
                )}
                {code.length > 140 && <PassiveInput id="code" value={code} />}
              </div>
              <div className={styles.row}>
                <Label modalHeading id="type">
                  File type
                </Label>
                <div className={styles.alignRow}>
                  <Radio
                    name="type"
                    id={shortid.generate()}
                    label="PNG"
                    checked={selectedType === 'png'}
                    onClick={(e): any => {
                      e.preventDefault()
                      setSelectedType('png')
                    }}
                  />
                  <Radio
                    name="type"
                    id={shortid.generate()}
                    label="JPG"
                    checked={selectedType === 'jpeg'}
                    onClick={(e): any => {
                      e.preventDefault()
                      setSelectedType('jpeg')
                    }}
                  />
                  <Radio
                    name="type"
                    id={shortid.generate()}
                    label="SVG"
                    checked={selectedType === 'svg'}
                    onClick={(e): any => {
                      e.preventDefault()
                      setSelectedType('svg')
                    }}
                  />
                </div>
              </div>

              {selectedType !== 'svg' && (
                <div className={styles.row}>
                  <Label modalHeading id="size">
                    Image dimensions
                  </Label>
                  {sizes.map((size) => (
                    <Radio
                      name="size"
                      id={shortid.generate()}
                      key={shortid.generate()}
                      label={size.label}
                      checked={selectedValue === size.value}
                      onClick={(): any => {
                        setSelectedValue(size.value)
                      }}
                    />
                  ))}
                </div>
              )}
            </Column>
          </TwoColumns>
        )}
      </ModalBody>
      <ModalFooter>
        <div className={styles.footerContainer}>
          {code.length > 1024 ? undefined : (
            <p className={styles.saveNote}>
              Always test your <strong>QR code</strong> before use!
            </p>
          )}
          <Button
            className={styles.downloadButton}
            disabled={code === '' || code.length > 1024}
            hideDisabled={code.length > 1024}
            onClick={
              code.length > 1024
                ? undefined
                : async () => {
                    if (ref && ref.current) {
                      if (selectedType === 'svg') {
                        const s = ref.current.querySelector('svg')

                        if (s) {
                          const content = s.innerHTML
                          const viewBox = s.getAttribute('viewBox')
                          const height = s.getAttribute('height')
                          const width = s.getAttribute('width')
                          const start = `<svg shape-rendering="crispEdges" height="${height}" width="${width}" viewBox="${viewBox}" xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">`
                          const blob = new Blob([`${start}${content}</svg>`], {
                            type: 'image/svg+xml',
                          })
                          await downloadQRCode(blob, selectedType, code)
                        }
                      } else {
                        const c = ref.current.querySelector('canvas')

                        if (c) {
                          c.toBlob(
                            // @ts-ignore
                            async (blob: Blob) => {
                              await downloadQRCode(blob, selectedType, code)
                            },
                            `image/${selectedType}`,
                            1.0,
                          )
                        }
                      }
                    }
                  }
            }
            yesLabel={code.length > 1024 ? undefined : 'Download QR code'}
          >
            Download QR code
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}
