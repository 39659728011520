import React from 'react'
import classNames from 'classnames'
import { useDropzone } from 'react-dropzone'

import styles from '../styles/file-drag-and-drop.module.scss'
import Button, { ButtonRow } from './button'
import { Preloader } from './loader'

interface Props {
  onDrop: (acceptedFiles: File[]) => void
  inProgress: boolean
  error?: string
  className?: string
  uploadButtonText?: string
}

export default function FileDragAndDrop({
  onDrop,
  inProgress,
  error = '',
  className,
  uploadButtonText = 'Upload file',
}: Props): React.ReactElement {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, maxFiles: 1 })

  const dropZoneClassNames = classNames(styles.dropZone, {
    [styles.dropZoneActive]: isDragActive,
    [styles.dropZoneAccept]: isDragAccept,
    [styles.dropZoneReject]: isDragReject,
  })

  return (
    <div className={className}>
      {!inProgress && (
        <div className={classNames(styles.dropZoneWrapper)}>
          <div {...getRootProps({ className: dropZoneClassNames })}>
            <input {...getInputProps()} />
            <ButtonRow className={styles.buttonRow}>
              {!isDragActive && (
                <>
                  <p>Drag &amp; drop or </p>
                  <Button>{uploadButtonText}</Button>
                </>
              )}
              {isDragActive && !isDragReject && <p>Drop your file here...</p>}
              {isDragActive && isDragReject && (
                <p>Sorry, we don't support multiple files...</p>
              )}
            </ButtonRow>
          </div>
        </div>
      )}

      {inProgress && (
        <div className={classNames(styles.dropZoneWrapper)}>
          <div className={styles.dropZone}>
            <Preloader />
          </div>
        </div>
      )}

      {error && (
        <p
          className={styles.error}
          dangerouslySetInnerHTML={{ __html: error }}
        />
      )}
    </div>
  )
}
