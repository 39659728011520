import moment from 'moment'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import TwoColumns, {
  Column,
  InnerBox,
  OuterBox,
} from '../components/two-columns'
import utmStyles from '../styles/utm-wiki.module.scss'
import UplifterInfo from '../components/uplifter-info'
import styles from '../styles/campaign-code-generator.module.scss'
import MiniSearchBar from '../components/mini-search-bar'
import { DataContext } from '../context/data-context'

export default function UtmWiki() {
  const { data, error } = useContext(DataContext)

  if (error) {
    return <div>{error}</div>
  }

  if (data) {
    return (
      <TwoColumns>
        <Column main>
          <h1>UTM wiki - everything you need to know about UTMs.</h1>
          <div className={utmStyles.utmWikiSearch}>
            <MiniSearchBar />
          </div>
          {data.map(
            ({ title, slug, excerpt, author, reading_time, internal }) => (
              <OuterBox key={title}>
                <InnerBox>
                  <Link to={`/utm-wiki/${slug}`}>
                    <h2>{title}</h2>
                    <p className={utmStyles.blogArticleDescription}>
                      {excerpt}
                    </p>
                    <div className={utmStyles.blogFootnoteGroup}>
                      <p className={utmStyles.blogFootnote}>
                        Reading time: {reading_time || '1 min'}
                      </p>
                      <p className={utmStyles.blogFootnote}>
                        Author: {author || 'UTM dog'}
                      </p>
                      <p className={utmStyles.blogFootnote}>
                        Last updated:{' '}
                        {moment(internal.updatedAt).format('DD/MM/YYYY')}
                      </p>
                    </div>
                  </Link>
                </InnerBox>
              </OuterBox>
            ),
          )}
        </Column>
        <Column fixed side>
          <OuterBox className={utmStyles.outerBox}>
            <InnerBox className={styles.rightInner}>
              <UplifterInfo />
            </InnerBox>
          </OuterBox>
        </Column>
      </TwoColumns>
    )
  }

  return <div>Loading...</div>
}
