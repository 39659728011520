import ToolTip from './components/tooltip'
import styles from './styles/article-template.module.scss'

export interface BlogPage {
  slug: string
  label: string
  title: string
  content: React.ReactChild
  description: string
  lastUpdated: string
  readingTime?: string
  author?: string
}

export const blogPages: BlogPage[] = [
  {
    slug: 'what-are-utm-codes',
    label: 'utm',
    title: 'What are UTM codes?',
    content: (
      <>
        <p>
          You might have heard them referred to as marketing codes, campaign
          codes, tracking links, CID codes (in Adobe Analytics), or query string
          parameters. In Google Analytics they are known as UTM codes.
        </p>
        <p>
          A UTM is the URL of your landing page plus a small piece of tracking
          code.
        </p>
        <p>
          All marketing activity linking visitors to your website (or mobile
          app) should use this unique tracking code.
        </p>
        <h4>UTMs may look something like this:</h4>
        <div className={styles.example}>
          <ToolTip message="The landing page URL of your campaign">
            <span className={styles.auditColor}>
              https://www.poshpets.com/offer
            </span>
          </ToolTip>
          <ToolTip message="Indicates all characters after the question mark are query string parameters and not part of the URL">
            ?
          </ToolTip>

          <ToolTip message="The first parameter name">
            <span className={styles.trackColor}>utm_source</span>
          </ToolTip>
          <ToolTip message="Separates the first parameter name from the first parameter value">
            =
          </ToolTip>
          <ToolTip message="The first parameter value, which can be read as this within your analytics platform">
            <span className={styles.explainColor}>facebook</span>
          </ToolTip>
          <ToolTip message="Indicates an additional query string parameter">
            &amp;
          </ToolTip>
          <ToolTip message="The second parameter name">
            <span className={styles.trackColor}>utm_campaign</span>
          </ToolTip>
          <ToolTip message="Separates the second parameter name from the second parameter value">
            =
          </ToolTip>
          <ToolTip message="The second parameter value, which can be read as this within your analytics platform">
            <span className={styles.explainColor}>january_sale</span>
          </ToolTip>
        </div>
        <p>
          Before the question mark, you will see the URL of your landing page:{' '}
          <span className={styles.boxed}>www.poshpets.com/offer</span>.
        </p>
        <p>
          People often forget to check the URL is live, and so end up driving
          expensive traffic to an error page. But Campaign Analytics platforms,
          like Uplifter, will check that the landing page is loading properly
          and has the correct analytics tags on it.{' '}
        </p>
        <p>
          After the question mark, you will see two parameters:{' '}
          <span className={styles.boxed}>utm_source</span> and
          <span className={styles.boxed}>utm_campaign</span>.
        </p>
        <p>
          Parameters are labels that identify values after the equals sign, in
          this case, <span className={styles.boxed}>facebook</span> and{' '}
          <span className={styles.boxed}>january_sale</span>.
        </p>
        <p>
          You can learn more about parameters{' '}
          <a href="https://utm.dog/utm-wiki/what-are-the-different-utm-parameters">
            here
          </a>
          .
        </p>
      </>
    ),
    description:
      'A UTM is the URL of your landing page plus a small piece of tracking code. All marketing activity linking visitors to your website (or mobile app) should use this unique tracking code...',
    lastUpdated: '18th November 2022',
  },

  {
    slug: 'what-does-utm-stand-for',
    label: 'utm',
    title: 'What does UTM stand for?',
    content: (
      <>
        <p>UTM stands for which stands for Urchin Tracking Module.</p>
        <p>
          The Urchin Software Corporation was the original inventor of what is
          now known as Google Analytics. The company was acquired by Google in
          2005, and although the current version of GA bears almost no
          resemblance to the original software, traces of the “Urchin” name
          still appear in the code!
        </p>
      </>
    ),
    description:
      'UTM stands for Urchin Tracking Module. The Urchin Software Corporation was the original inventor of what is now known as Google Analytics...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'why-do-we-need-utm-codes',
    label: 'utm',
    title: 'Why do we need UTM codes?',
    content: (
      <>
        <p>
          You want to measure everything in digital, particularly the traffic
          from campaigns.
        </p>
        <p>
          Web analytics tools, like Google Analytics, Adobe Analytics, Matomo or
          Piano, can identify where each visitor has come from (the referring
          domain) but they won't know what campaign or what creative the visitor
          clicked on.
        </p>
        <p>
          Adservers report well on ad performance, but give a very limited view
          of the visitors' path to conversion.
        </p>
        <p>
          Ad platforms, like Google Ads, Facebook Ads and Linkedin, are similar.
          And so are email marketing platforms and social media analytics
          platforms.
        </p>
        <p>
          This makes it hard to compare performance across campaigns and between
          channels.
        </p>
        <p>UTM codes make this possible.</p>
        <p>
          With the demise of cookies and the rise of privacy legislation, there
          are fewer accurate ways of tracking performance from one platform to
          another.
        </p>
        <p>
          UTM codes are like the synapses in a digital brain; little connections
          from your advertising to the Google Analytics on your website or in
          your mobile app, transferring data about the marketing that has made
          that visitor click through.
        </p>
        <p>
          Nothing else can track journeys across all types of media - little
          will get you as close to an end-to-end view of marketing performance.
        </p>
      </>
    ),
    description:
      'With the demise of cookies and the rise of privacy legislation, there are fewer accurate ways of tracking performance from one platform to another. UTM codes are like the synapses in a digital brain...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'what-do-utms-codes-help-us-answer',
    label: 'utm',
    title: 'What do UTM codes help us answer?',
    content: (
      <>
        <p>UTM codes help you to answer:</p>
        <ul>
          <li>Which campaign should we spend more (or less) money on?</li>
          <li>
            Which is the best channel to get additional engaged traffic to our
            website?
          </li>
          <li>
            Which ad formats give us the most engagement and onsite sales?
          </li>
          <li>Which email offer generated the most profit on our website?</li>
          <li>
            Which search engine keyword groups should we spend more money on?
          </li>
          <li>What are the demographics of our campaign visitors?</li>
          <li>
            What content or products are visitors from campaigns interested in?
          </li>
          <li>How did our campaign visitors behave and navigate on-site?</li>
          <li>
            How much website activity did our offline media campaigns generate?
          </li>
        </ul>
      </>
    ),
    description:
      'Whilst tracking your marketing campaign, your UTM codes can help you answer many questions such as: Which campaign should we spend more (or less) money on? Which is the best channel to get additional engaged traffic to our website?...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'why-is-it-critical-to-get-utm-codes-right',
    label: 'utm',
    title: 'Why is it so critical to get UTM codes right?',
    content: (
      <>
        <p>
          If these links have errors in them, you won't be able to produce a
          simple campaign report, let alone be able to figure out attribution.
        </p>
        <p>
          Also, the visitor might find themselves landing on a 404 error page or
          getting quite a different experience to the one you planned.
        </p>
        <p>
          UTM codes will get the right ad data into your web analytics platform.
        </p>
        <p>
          Not using UTM codes significantly reduces your ability to understand
          the performance of your marketing efforts, and could lead to wasted
          budgets and effort.
        </p>
      </>
    ),
    description:
      "If these links have errors in them, you won't be able to produce a simple campaign report, let alone be able to figure out attribution...",
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'what-are-the-different-utm-parameters',
    label: 'utm',
    title: 'What are the different UTM parameters?',
    content: (
      <>
        <p>
          UTMs contain parameters: fields that allow you to filter and compare
          your campaign activity by different dimensions in Google Analytics.
          For example, you might only want to see the traffic from one campaign,
          and split by the different sources - you can do this by filtering on
          the values in different parameters.
        </p>
        <p>
          It is very important to establish the parameters you need and the
          values that you are going to use, and make sure that everyone uses
          them.
        </p>
        <h4>
          There are five UTM parameters that Google Analytics recommends using:
        </h4>
        <table>
          <thead>
            <tr>
              <th>Parameter</th>
              <th>What it identifies...</th>
              <th>Example values</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className={styles.boxed}>utm_source</span>
              </td>
              <td>The place that the ad or post was placed</td>
              <td>google, facebook, newsletter, tradeshow</td>
            </tr>
            <tr>
              <td>
                <span className={styles.boxed}>utm_medium</span>
              </td>
              <td>The channel that led the user to your website</td>
              <td>paid search, display, email, cpc, poster</td>
            </tr>
            <tr>
              <td>
                <span className={styles.boxed}>utm_campaign</span>
              </td>
              <td>A specific product promotion or strategic campaign</td>
              <td>monthly_newsletter, january_sale, shoes_sale_june</td>
            </tr>
            <tr>
              <td>
                <span className={styles.boxed}>utm_term</span>
              </td>
              <td>
                In Paid Search, use this to identify the keywords for the ad
              </td>
              <td>running+shoes, brandterms</td>
            </tr>
            <tr>
              <td>
                <span className={styles.boxed}>utm_content</span>
              </td>
              <td>
                In content-targeted ads, use this to identify different
                creatives
              </td>
              <td>logolink, textlink, imagelink</td>
            </tr>
          </tbody>
        </table>
        <p>
          Sometimes five parameters isn't enough. Advanced users can add custom
          parameters to the UTM code if they know what they are doing. If you
          need to do this, you will need a campaign analytics platform like
          Uplifter.
        </p>
        <p>
          In Google Analytics, any additional traffic parameters (sometimes
          called “dimensions”) can be found under Default Channel Grouping.
        </p>
        <p>
          The Values are read by your analytics tool and stored against the
          parameter.
        </p>
        <p>
          In the example above, the value for the{' '}
          <span className={styles.boxed}>utm_source</span> is{' '}
          <span className={styles.boxed}>facebook</span> and for the
          <span className={styles.boxed}>utm_campaign</span> is{' '}
          <span className={styles.boxed}>january_sale</span>.
        </p>
        <p>
          It is important to establish the values you need to track all your
          different campaign variations, and make sure that everyone uses the
          exact same ones.
        </p>
        <p>
          If they misspell any of the parameters or values, Google Analytics
          will see this traffic as completely separate from the rest.
        </p>
        <p>
          It is also important that someone centrally manages requests for new
          values to be added to the list, otherwise people will invent their
          own.
        </p>
      </>
    ),
    description:
      'UTMs contain parameters: fields that allow you to filter and compare your campaign activity by different dimensions in Google Analytics. There are five UTM parameters that Google Analytics recommends...',
    lastUpdated: '18th November 2022',
  },

  {
    slug: 'UTM-best-practice',
    label: 'utm',
    title: 'What is the best practice for UTM management?',
    content: (
      <>
        <p>There are three over-riding principles to good UTM management:</p>
        <ol>
          <li>
            <strong>Good governance.</strong> Make sure the whole UTM process is
            clearly defined, has an owner and can be adapted to suit the
            changing needs of the business.
          </li>
          <li>
            <strong>Quality data.</strong> Make sure no channels or campaigns
            are missing and that data is clean and not corrupted.
          </li>
          <li>
            <strong>Easy-to-use data.</strong> Data should be easy for users to
            access, interpret and use for analysis and decision-making.
          </li>
        </ol>
        <p>This is what we would recommend as best practice:</p>
        <ol>
          <li>
            <strong>Create a "Taxonomy Guardian".</strong> Designate someone to
            be accountable for making sure all campaigns are tracked correctly.
            Internal marketers and external media agencies often forget to apply
            codes, or make mistakes, affecting the quality of your analytics
            data and your team's ability to make data-driven decisions.
          </li>
          <li>
            <strong>Don't use a spreadsheet.</strong> We know we gave you a free
            one, but spreadsheets don't scale well. They easily break and can be
            troublesome when they are shared with multiple users - the more
            users, the bigger the problems. Downloadable spreadsheets can be
            very hard to keep track of - users end up using out-of-date
            versions, or hacking them to suit their needs. It can also be hard
            to enforce naming conventions and rules around uniformity,
            hierarchy, spelling, and capitalisation. They certainly do not
            provide a record of all links created or a user history for
            accountability. Basically, don't use them.
          </li>
          <li>
            <strong>Never retype the UTM by hand.</strong> Always copy and paste
            the whole link from Uplifter or the CSV download. This ensures the
            URL is correct and stops human error. Better still, copy the short
            link which is much easier to handle.
          </li>
          <li>
            <strong>Name campaigns clearly.</strong> Your campaign names should
            be simple and use recognisable terms, in a good canonical structure
            (the name should start with campaign group and end with the date).
            This enables users to understand what data they are looking at
            without having to use a lookup table to decipher its meaning.
          </li>
          <li>
            <strong>No inappropriate language.</strong> It is possible for your
            end users to see the campaign link and therefore it should not
            contain inappropriate or discriminatory language.
          </li>
          <li>
            <strong>No long parameter names.</strong> Keep any names under 20
            characters and your whole URL under 242 characters.
          </li>
          <li>
            <strong>Be careful with special characters.</strong> Follow these
            rules to stop the code from breaking:
            <ul>
              <li>
                Question Marks should never be used, except at the start of the
                query string
              </li>
              <li>
                Ampersands should only be used as connectors between parameters
              </li>
              <li>
                Equals symbols should only be used between a parameter name and
                its value
              </li>
              <li>
                Spaces in a code should be replaced by underscores or pipe
                symbols. Spaces could either break the code or be represented as
                a messy '%20'
              </li>
              <li>
                Upper case should be avoided. Use lower case letters wherever
                possible to make your reports easier to read.
              </li>
            </ul>
          </li>
          <li>
            <strong>No need to use ALL the parameters.</strong> Don't label the
            term or content fields as 'none' if they are not actively being
            used. Just complete the necessary fields.
          </li>
          <li>
            <strong>Don't use the same code twice.</strong> Using the same code
            for a repeat campaign, will combine all the traffic, conversions and
            behaviour into one pot you cannot separate. Add an identifier so you
            can tell them apart.
          </li>
          <li>
            <strong>Beware of redirects.</strong> If your landing page has a
            redirect, you need to implement campaign links on both the original
            landing page and the redirect page. Otherwise, the original landing
            page could be picked up as the referrer of traffic.
          </li>
          <li>
            <strong>Don't use campaign links on internal website links.</strong>{' '}
            If you do, your campaign data will be overwritten, and you will lose
            the original tracking source. You can use internal promotion code
            parameters for tracking internal links.
          </li>
        </ol>
      </>
    ),
    description:
      'There are three over-riding principles to good UTM management; here are our top tips for keeping in line with these principles...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'how-do-i-use-campaign-links-for-offline-marketing',
    label: 'utm',
    title: 'How do I use campaign links for offline marketing?',
    content: (
      <>
        <p>
          Offline marketing campaigns do not have to be invisible to your
          analytics platform. They can be tracked using redirect domains, vanity
          URLs and QR codes.
        </p>
        <p>
          These will help you compare offline marketing efforts, to see which
          ones bring the most engaged traffic to your website.
        </p>
      </>
    ),
    description:
      'Offline marketing campaigns do not have to be invisible to your analytics platform. They can be tracked using redirect domains, vanity URLs and QR codes...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'what-are-redirect-domains',
    label: 'shortLinks',
    title: 'What are Redirect Domains?',
    content: (
      <>
        <p>
          A redirect domain is memorable domain that then redirects to a unique
          landing page with a campaign link page on your website.
        </p>
        <h4>Example:</h4>
        <p>
          A printed flyer handed out in London could use LondonPets.com as the
          URL for more info, but it redirects to
          <span className={styles.boxed}>
            poshpets.com/promo?utm_source=
            <strong>london</strong>&amp;utm_medium=flyer
          </span>
        </p>
        <p>
          A printed flyer handed out in Manchester could use ManchesterPets.com,
          which redirects to
          <span className={styles.boxed}>
            poshpets.com/promo?utm_source=
            <strong>manchester</strong>&amp;utm_medium=flyer
          </span>
        </p>
      </>
    ),
    description:
      'A redirect domain is memorable domain that then redirects to a unique landing page with a campaign link page on your website...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'what-are-short-links',
    label: 'shortLinks',
    title: 'What are short links (vanity URLs)?',
    content: (
      <>
        <p>
          Short links allow you to create short, simple, memorable links, which
          build trust with your audience and increase clickthrough rates.
        </p>
        <p>They are made up of two parts:</p>
        <ol>
          <li>
            A short domain name, for example{' '}
            <span className={styles.boxed}>posh.co</span>
          </li>
          <li>
            A URL slug, for example <span className={styles.boxed}>/offer</span>
          </li>
        </ol>
        <p>
          The complete short link would be posh.co/offer. When users click on
          that short link, they are redirected to a specific landing page on
          your website, with UTM codes attached.
        </p>
        <p>
          You don't need to use a branded short link though. The short link in
          UTM.dog will give you a generic short link that may lack the elegance
          of your own domain and a memorable slug, but functionally, it works
          just the same way and it's free. There's no better way to create free
          UTM short links.
        </p>
        <p>
          If you do want to customise it in any way, just upgrade to Uplifter.
        </p>
        <p>
          Uplifter can also report on how many short links were expanded, giving
          you an “actual clickthrough” metric to compare with what the adserver
          or Google Analytics tells you.
        </p>
      </>
    ),
    description:
      'Short links allow you to create short, simple, memorable links, which build trust with your audience and increase clickthrough rates...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'when-should-i-use-short-links',
    label: 'shortLinks',
    title: 'When should I use short links?',
    content: (
      <>
        <p>
          We recommend using short links for ALL digital drive-to-web
          clickthrough activity.
        </p>
        <p>Why? For a number of reasons:</p>
        <ol>
          <li>
            They are short so much easier to paste into your various digital
            marketing platforms
          </li>
          <li>Short links are way more memorable for users than long links</li>
          <li>
            They give a very accurate count of “actual clickthroughs” - which is
            not possible using cookie-based analytics (you will need Uplifter to
            do this)
          </li>
          <li>
            Mistakes in the UTM code can be corrected after the short link has
            been deployed (you will need Uplifter to do this)
          </li>
        </ol>
        <p className={styles.note}>
          <strong>
            <strong>Note:</strong>
          </strong>
          In Google Analytics, you will see no evidence that a short link has
          been used - you will only see the full UTM code.
        </p>
      </>
    ),
    description:
      'We recommend using short links for ALL digital drive-to-web clickthrough activity. Why? For a number of reasons! Click here to learn more about when to use short links...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'how-do-i-use-short-links',
    label: 'shortLinks',
    title: 'How do I use short links?',
    content: (
      <>
        <p>
          Short links require a free Uplifter account to create. On link type,
          click on 'Short links' and 'Create a free account' button to setup
          your Uplifter account.
        </p>
        <p>
          Whenever you create a new link in Uplifter, you will be given a full
          UTM long link plus its associated short link. It will look like this:
          <span className={styles.boxed}>https://upl.inc/a4s5d6</span>
        </p>
        <p className={styles.note}>
          <strong>
            <strong>Note:</strong>
          </strong>
          You can change the URL slug to be easier to remember (e.g.
          https://upl.inc/deals) if term you want is not already in use.
        </p>
        <p>
          When you deploy your short link and someone clicks through on it,
          they'll be redirected to your landing page with all of the parameters
          as if they'd clicked on the full UTM code.
        </p>
      </>
    ),
    description:
      "Short links require a free Uplifter account to create. On link type, click on 'Short links' and 'Create a free account' button to setup your Uplifter account...",
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'how-do-i-customise-short-links',
    label: 'shortLinks',
    title: 'How do I customise short links?',
    content: (
      <>
        <p>
          First, you will need to upgrade to Uplifter Enterprise Edition and
          purchase the Custom Link Shortener option.
        </p>
        <p>
          Second, you will need to buy your own branded short domain through a
          company like GoDaddy (or we can do it for you). Once you have a short
          domain, we will set it up for you in Uplifter.
        </p>
        <p>
          Then, whenever any of your users log into Uplifter, they will see your
          domain appear as the short link.
        </p>
        <p>
          They will also be able to change the URL slug to something more
          memorable, as long as it hasn't been used for another link already.
        </p>
      </>
    ),
    description:
      "Once you've set up your Uplifter account and bought your branded domain, which can be customised as a short link in Uplifter...",
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'what-is-a-qr-code',
    label: 'qr',
    title: 'What is a QR code?',
    content: (
      <>
        <p>
          A QR code is a two-dimensional barcode. It was invented in 1994 by the
          Japanese automotive company Denso Wave to hold a lot more information
          than a traditional one-dimensional barcode. QR stands for Quick
          Response.
        </p>
        <p>
          In marketing, QR codes often contains a URL. A person can scan the QR
          code using their smart phone camera and go straight to that webpage or
          app. The URL can contain the full UTM code, or a short-link to the
          full UTM code.
        </p>
        <p>
          With Uplifter you can see how many people scanned each QR code to
          compare offline marketing activity and track their actions online.
        </p>
      </>
    ),
    description:
      'A QR code is a two-dimensional barcode. It was invented in 1994 by the Japanese automotive company Denso Wave to hold a lot more information than a traditional one-dimensional barcode...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'what-is-a-dynamic-qr-code',
    label: 'qr',
    title: 'What is a Dynamic QR code?',
    content: (
      <>
        <p>
          A Dynamic QR Code is a QR code that uses a short link that can be
          updated AFTER the QR code has been created.
        </p>
        <h4>Why is this important?</h4>
        <p>
          Imagine you have printed a QR code on your exhibition stand. You have
          added the UTM tracking correctly and even added a value for the
          exhibition that the stand will be used at. Anyone at the show who
          scans the QR code will be directed to your landing page and Google
          analytics will pick up all the information about where the visitor
          came from. But what happens if that stand is used at another event, in
          the future? The old exhibition still appears in the UTM… The original
          exhibition will be given credit for the traffic from the new
          exhibition.
        </p>
        <p>
          A Dynamic QR Code fixes this. If you have created a Dynamic QR code in
          Uplifter, you can change the UTM each time the stand is reused. No
          need to reprint the stand. You can even add new parameters to it -
          changes to the taxonomy that didn't exit when you first created the QR
          code.
        </p>
        <p>
          This is great news for offline marketers. They don't need to reprint
          their collateral each time it is used.
        </p>
      </>
    ),
    description:
      'A Dynamic QR Code is a QR code that uses a short link that can be updated AFTER the QR code has been created. Why is this important?...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'when-should-i-use-a-qr-code',
    label: 'qr',
    title: 'When should I use a QR code?',
    content: (
      <>
        <p>
          QR codes provide a useful way of connecting offline marketing
          campaigns with an online journey, giving your audience instant access
          to more info about your products and services.
        </p>
        <p>
          They can be used in any setting where users have the ability (and
          incentive) to scan the QR image to access a webpage, on posters, on
          exhibition stands, on printed collateral, even in TV broadcasts.
        </p>
      </>
    ),
    description:
      'QR codes provide a useful way of connecting offline marketing campaigns with an online journey, giving your audience instant access to more info about your products and services...',
    lastUpdated: '18th November 2022',
  },
  {
    slug: 'how-do-i-create-a-qr-code-for-my-utm-link',
    label: 'qr',
    title: 'How do I create a QR code for my UTM links?',
    content: (
      <>
        <p>
          UTM.dog will automatically generate a QR code for each UTM you create.
        </p>
        <p>
          You can download it as a JPG, PNG or SVG. You can also add your own
          favicon to the centre, match it to your brand colours, and you can
          choose the image size. It is not dynamic.
        </p>
        <p>
          If you require a Dynamic QR Code - one where the link can be updated
          after it's created - you will need to upgrade to Uplifter.
        </p>
      </>
    ),
    description:
      'UTM.dog will automatically generate a QR code for each UTM you create. You can download it as a JPG, PNG or SVG and customise it by...',
    lastUpdated: '18th November 2022',
  },
]
