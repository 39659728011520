import { useRef } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import styles from '../styles/button.module.scss'

interface ButtonRowProps {
  children: React.ReactNode
  className?: string
  style?: object
  centerAlign?: boolean
}
export function ButtonRow({
  children,
  className = '',
  style,
  centerAlign,
}: ButtonRowProps): React.ReactElement {
  const buttonClassNames = classNames(className, styles.buttonRow, {
    [styles.centerAlign]: centerAlign,
  })
  return (
    <div className={buttonClassNames} style={style}>
      {children}
    </div>
  )
}

type NativeButtonProps = any

type buttonTypes =
  | 'container'
  | 'containerPink'
  | 'hollowBorder'
  | 'selectBox'
  | 'delete'
  | 'flatPink'
  | 'pinkBorder'
  | 'flatBlue'
  | 'link'
  | 'edit'
  | 'clear'
  | 'share'
  | 'copy'
  | 'image'
  | 'swatch'
  | 'qrCode'

interface Props extends NativeButtonProps {
  children?: React.ReactNode
  onClick?: (event: any) => void
  style?: object
  className?: string
  type?: string | buttonTypes
  href?: string
  to?: string
  newTab?: boolean
  submit?: boolean
  disabled?: boolean
  ariaLabel?: string
  download?: boolean
  pill?: boolean
  align?: string
  toolTip?: string
  noBorder?: boolean
  color?: string
  onMouseOver?: any
  onMouseLeave?: any
}

export default function Button({
  children = '',
  onClick,
  className,
  type = 'container',
  href,
  to,
  newTab = false,
  submit = false,
  style,
  disabled,
  ariaLabel,
  download,
  align,
  pill = false,
  toolTip = '',
  noBorder = false,
  onMouseOver,
  onMouseLeave,
  id,
}: Props): React.ReactElement {
  const buttonRef: any = useRef<HTMLButtonElement>(null)

  const rest = { style, disabled, download, onMouseLeave, id }
  const buttonClassNames = classNames(className, {
    [styles.pill]: pill,
    [styles.alignLeft]: align === 'left',
    [styles.alignRight]: align === 'right',
    [styles[type]]: true,
    [styles.hasTooltip]: toolTip !== '',
    [styles.noBorder]: noBorder,
    [styles.disabled]: disabled,
  })

  const toolTipMarkup =
    toolTip !== '' ? <span className={styles.tooltip}>{toolTip}</span> : null

  if (to) {
    return (
      <Link to={to} className={buttonClassNames}>
        {children}
      </Link>
    )
  }

  if (href) {
    const ifNewTab = newTab
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {}

    return (
      <a
        className={buttonClassNames}
        href={href}
        aria-label={ariaLabel}
        {...ifNewTab}
        {...rest}
        onClick={(e: any) => {
          if (disabled) {
            e.preventDefault()
          }
          if (onClick) {
            onClick(e)
          }
        }}
      >
        <span>{children}</span>
        {toolTipMarkup}
      </a>
    )
  }

  return (
    <button
      ref={buttonRef}
      className={buttonClassNames}
      aria-label={ariaLabel}
      onClick={onClick}
      {...rest}
      onMouseOver={() => {
        if (onMouseOver && buttonRef && buttonRef.current) {
          onMouseOver(buttonRef.current)
        }
      }}
      type={submit ? 'submit' : 'button'}
    >
      {children}
      {toolTipMarkup}
    </button>
  )
}
