/* eslint-disable react/no-array-index-key */
import Button, { ButtonRow } from './button'
import ToolTip from './tooltip'
import useMobile from '../hooks/useMobile'
import styles from '../styles/uplifter-benefits.module.scss'
import gaEvent from '../utils/tracking'

interface Benefit {
  title: string
  tooltip: string
}

const benefits: Benefit[] = [
  {
    title: 'Full customisation',
    tooltip:
      'Tailor your campaign taxonomy to your business. Add new parameters for business units, products, geographies and more.',
  },
  {
    title: 'Eliminate tracking errors',
    tooltip:
      'Enforce standard choices from pre-approved dropdowns. Set time limits for campaigns.',
  },
  {
    title: 'Multiple users',
    tooltip:
      'Create teams with admins. Add/remove users. Approve requests for new parameters.',
  },
  {
    title: 'Branded short links',
    tooltip:
      'Create short links with your own URL and memorable codes. Compare clickthroughs on all marketing activity without cookies.',
  },
  {
    title: 'Dynamic QR codes',
    tooltip:
      "Create branded QR codes. Update the UTM code behind each QR codes *after* they've been generated.",
  },
  {
    title: 'User-friendly dashboards',
    tooltip:
      "Quickly discover what ads are working and what aren't. Easily create and save campaign reports, using GA data filtered by your UTM parameters.",
  },
  {
    title: 'Landing page monitoring',
    tooltip:
      'Is your landing page live, fast and correctly tagged? Automatically monitor critical pages every day of the campaign.',
  },
  {
    title: 'Analytics integration',
    tooltip:
      'See all your campaign metrics in one place. Our APIs are compatible with 99% of email marketing platforms, adservers, campaign managers, CRM systems and analytics platforms.',
  },
  {
    title: 'Enterprise support',
    tooltip:
      'Real-person support for every marketer and agency wherever they work. Get your global marketing team up and running in minutes, not weeks.',
  },
]

export default function UplifterBenefits(): React.ReactElement {
  const mobile = useMobile()

  return (
    <>
      <h2>Need more than a few UTMs?</h2>
      <p style={{ marginBottom: 0 }}>Upgrade to Uplifter for:</p>
      <ul className="logoList">
        {benefits.map(({ title, tooltip }, index) => (
          <li key={`benefit-${index}`} className={styles.uplifterListItem}>
            {title}
            <ToolTip
              onActive={(active: boolean) => {
                if (active)
                  gaEvent('tooltip_hover', {
                    tooltip_section: 'uplifter-benefits',
                    tooltip_name: title,
                  })
              }}
              showOnLeft={!mobile}
              className={styles.benefitTooltip}
              message={tooltip}
            />
          </li>
        ))}
      </ul>
      <ButtonRow className={styles.buttonRow}>
        <Button
          type="containerPink"
          href="https://upl.inc/Hz2vyA"
          newTab
          style={{ marginBottom: 0 }}
          id="tryUplifter"
        >
          Upgrade to Uplifter
        </Button>
        <Button
          href="https://upl.inc/WM9Fmf"
          newTab
          style={{ marginBottom: 0 }}
          type="flatPink"
          id="moreInfo"
        >
          Learn more about Uplifter
        </Button>
      </ButtonRow>
    </>
  )
}
