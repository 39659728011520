import { useState } from 'react'

import Input from './inputs'
import { FieldSlot, FormRow, LabelSlot } from './row'
import ToolTip from './tooltip'
import styles from '../styles/campaign-code-generator-rows.module.scss'
import { prepareInput } from '../utils'
import { queryLength, urlLength } from '../core/generated-structure'
import Label from './labels'

interface InputRowProps {
  onValidation: (valid: boolean) => void
  updateForm: (value: string) => void
  id: string
  name: string
  tooltip: string
  last?: boolean
  className?: string
  optional?: boolean
  children?: any
}

export function InputRow({
  onValidation,
  updateForm,
  id,
  name,
  tooltip,
  last,
  className,
  optional = false,
  children = null,
}: InputRowProps): React.ReactElement {
  const [error, setError] = useState('')
  const [warning, setWarning] = useState('')

  const [currentValue, setCurrentValue] = useState('')

  const maxLength = id === 'landing-page' ? urlLength : queryLength

  return (
    <FormRow className={className}>
      <LabelSlot className={styles.labelSlot}>
        <Label optional={optional} id={`input-${id}`}>
          {name}
        </Label>
        <ToolTip showOnRight className={styles.tooltip} message={tooltip} />
      </LabelSlot>
      <FieldSlot>
        <Input
          autoCapitalize="none"
          showClear
          error={error}
          id={`input-${id}`}
          name={`input-${name}`}
          value={currentValue}
          updateUrl={
            id === 'landing-page'
              ? (newVal: string) => {
                  updateForm(newVal)
                }
              : undefined
          }
          beforeChange={(inputValue: string) => {
            setWarning('')

            const outputValue = prepareInput(inputValue)

            if (inputValue !== outputValue) {
              setWarning('Avoid uppercase, spaces and special characters (?&=)')
            }

            if (id === 'landing-page') return inputValue

            return outputValue
          }}
          onValueChange={(val: string): void => {
            updateForm(val)
            setCurrentValue(val)
            setError('')

            const valid = val.length <= maxLength

            onValidation(valid)

            if (!valid)
              setError(`We recommend less than ${maxLength} characters.`)
          }}
          placeholder={`Type ${name}`}
        />

        {error && <p className={styles.footNoteError}>{error}</p>}
        {warning && <p className={styles.footNoteWarning}>{warning}</p>}

        {children}
      </FieldSlot>
    </FormRow>
  )
}
