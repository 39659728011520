import { useState } from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'

import styles from '../styles/tooltip.module.scss'
import useResize from '../hooks/useResize'

interface BoxProps {
  message: string | React.ReactNode
  className?: string
}

export function Box({ message, className }: BoxProps): React.ReactElement {
  return (
    <div className={classNames(className, styles.box)}>
      {typeof message === 'string' ? (
        <ReactMarkdown linkTarget="_blank">{message}</ReactMarkdown>
      ) : (
        message
      )}
    </div>
  )
}

interface Props {
  message: string | React.ReactNode
  children?: React.ReactNode
  inline?: boolean
  className?: string
  onActive?: (active: boolean) => void
  showUnder?: boolean
  showOnRight?: boolean
  showOnLeft?: boolean
}

export default function ToolTip({
  message,
  children,
  inline,
  className,
  onActive,
  showUnder,
  showOnRight,
  showOnLeft,
}: Props): React.ReactElement | null {
  const [active, setActive] = useState(false)
  const screenWidth = useResize()
  const isMobile = screenWidth <= 768

  if (!message) {
    return null
  }

  const c = classNames(className, {
    [styles.container]: active,
    [styles.containerHidden]: !active,
    [styles.showUnder]: showUnder,
    [styles.showOnRight]: showOnRight,
    [styles.showOnLeft]: showOnLeft,
    [styles.narrow]: isMobile,
  })

  const elActive = (activeFlag: boolean) => {
    if (onActive) {
      onActive(activeFlag)
    }
    setActive(activeFlag)
  }

  return (
    <div
      className={c}
      onMouseEnter={(): void => elActive(true)}
      onMouseLeave={(): void => elActive(false)}
      onFocus={(): void => elActive(true)}
      onBlur={(): void => elActive(false)}
      style={{ ...(inline ? { display: 'inline-block' } : {}) }}
    >
      {children ? (
        <div
          className={styles.children}
          style={{ ...(inline ? { display: 'inline-block' } : {}) }}
        >
          {children}
        </div>
      ) : (
        <span className={styles.dot} />
      )}
      <div
        aria-label={active ? 'visible' : 'hidden'}
        style={{ ...(inline ? { display: 'block' } : {}) }}
      >
        <Box message={message} />
      </div>
    </div>
  )
}
