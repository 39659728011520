import { Dispatch, SetStateAction } from 'react'
import shortid from 'shortid'

import styles from '../styles/button-tabs.module.scss'
import { Radio } from './inputs'

interface ButtonTabsProp {
  currentTab: number
  setCurrentTab: Dispatch<SetStateAction<number>>
  tabsLabels: string[]
  label?: string
  children: React.ReactNode
  fwdRef?: React.LegacyRef<HTMLDivElement>
}

export default function ButtonTabs({
  currentTab,
  setCurrentTab,
  label,
  children,
  tabsLabels,
  fwdRef = null,
}: ButtonTabsProp): React.ReactElement {
  return (
    <div ref={fwdRef} className={styles.styleTabs}>
      <div className={styles.tabsOuterWrapper}>
        {label && (
          <p>
            <strong>{label}</strong>
          </p>
        )}
        <div className={styles.tabsWrapper}>
          {tabsLabels.map((item, index) => {
            const checked = currentTab === index
            return (
              <Radio
                key={item}
                name="select-view"
                id={shortid.generate()}
                label={item}
                type="radio"
                value={item}
                checked={checked}
                onClick={() => {
                  setCurrentTab(index)
                }}
              />
            )
          })}
        </div>
      </div>
      <div className={styles.content}>
        {Array.isArray(children)
          ? children.map((child, index) => {
              if (index === currentTab) return child
              return null
            })
          : children}
      </div>
    </div>
  )
}
