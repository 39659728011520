import { Dispatch, ReactElement, SetStateAction } from 'react'

import mascot from '../assets/mascot-facing-left.png'
import styles from '../styles/intro-content.module.scss'

interface IntroContentProps {
  setLearnBoxesState: Dispatch<SetStateAction<boolean[]>>
  learnRef: React.RefObject<HTMLDivElement>
  formRef: React.RefObject<HTMLFormElement>
}

export default function IntroContent({
  setLearnBoxesState,
  learnRef,
  formRef,
}: IntroContentProps): ReactElement {
  return (
    <div className={styles.container}>
      <div>
        <h1 style={{ margin: 0 }}>UTMDog - The best free UTM builder</h1>
        <ul className={styles.logoList}>
          <li style={{ marginBottom: 0 }}>
            <a href="/utm-wiki">
              Learn about UTM best practice in our UTM Wiki.
            </a>
          </li>
          <li>
            <a
              href="#learn"
              onClick={(e) => {
                e.preventDefault()

                formRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                  inline: 'start',
                })
              }}
            >
              Quickly create error-free UTM codes and QR codes for your
              campaigns.
            </a>
          </li>
          <li style={{ marginBottom: 0 }}>
            <a
              href="https://support.google.com/analytics/answer/1033863"
              target="_blank"
              rel="noreferrer"
            >
              Measure and understand performance in Google Analytics.
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.mascotContainer}>
        <img className={styles.mascot} src={mascot} alt="utm.dog mascot" />
      </div>
    </div>
  )
}
