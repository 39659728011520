import { createRef, useState } from 'react'

import TwoColumns, {
  Column,
  InnerBox,
  OuterBox,
} from '../components/two-columns'
import ButtonTabs from '../components/button-tabs'
// import CodeGeneratorBulkUpload from './code-generator-bulk-upload'
import CodeGeneratorSingle from '../components/code-generator-single'
import CodeGeneratorUtmSpreadsheet from '../components/code-generator-utm-spreadsheet'
import GeneratedCodeResults from '../components/campaign-code-generator-results'
import UplifterBenefits from '../components/uplifter-benefits'
import { ShowLatestCode } from '../components/show-latest-code'
import defaultLogoImage from '../assets/utm_dog_bw_right.png'
import styles from '../styles/campaign-code-generator.module.scss'
import IntroContent from '../components/intro-content'
import { blogPages } from '../all-blog-pages'

export default function CampaignCodeGenerator() {
  const learnContentRef = createRef<HTMLDivElement>()
  const formContentRef = createRef<HTMLFormElement>()
  const [currentTab, setCurrentTab] = useState(0)
  const [learnBoxesState, setLearnBoxesState] = useState<boolean[]>(
    Array(blogPages.length).fill(false, 0),
  )

  // Generated codes
  const lsCodes = window.localStorage.getItem('prevCodes')
  const [codes, setCodes] = useState<GeneratedCode[]>(
    lsCodes ? JSON.parse(lsCodes) : [],
  )
  const [generatedCode, setGeneratedCode] = useState('')
  const [imageFile, setImageFile] = useState(defaultLogoImage)

  return (
    <TwoColumns>
      <Column main>
        <div>
          <IntroContent
            learnRef={learnContentRef}
            formRef={formContentRef}
            setLearnBoxesState={setLearnBoxesState}
          />
          <ButtonTabs
            tabsLabels={['Create online', 'Create using UTM spreadsheet']}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          >
            <CodeGeneratorSingle
              ref={formContentRef}
              setGeneratedCode={setGeneratedCode}
              codes={codes}
              setCodes={setCodes}
            />
            <CodeGeneratorUtmSpreadsheet />
          </ButtonTabs>
          {generatedCode !== '' && (
            <ShowLatestCode
              generatedCode={generatedCode}
              imageFile={imageFile}
              setImageFile={setImageFile}
            />
          )}
        </div>
      </Column>
      <Column fixed side className={styles.rightColumn}>
        <OuterBox>
          <InnerBox className={styles.rightInner}>
            {codes.length > 0 && (
              <>
                <GeneratedCodeResults
                  generatedCode={generatedCode}
                  codes={codes}
                  setCodes={setCodes}
                  imageFile={imageFile}
                  setImageFile={setImageFile}
                />
                <div className={styles.divider} />
                {/* <Feedback />
                <div className={styles.divider} /> */}
              </>
            )}
            <UplifterBenefits />
          </InnerBox>
        </OuterBox>
      </Column>
    </TwoColumns>
  )
}
