export const siteContainerWidth = 1200
export const mobileBreakpoint = 1024

export const messages = {
  fileUploadError: 'We are having trouble uploading your file.',
  urlStatus: {
    slowLandingPage: `This page is slow (loads in over 4 seconds). [Fix](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-)`,
    redirectedLandingPage: `This page redirects to another page. [Fix](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-)`,
    valid: 'Valid page (live, fast, has analytics and no redirects).',
    invalid: `This page may not have analytics. [Fix](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-)`,
    badUrl:
      'This page is not live. [Fix](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-)',
  },
  linkTypes: `**Full link** - Will create a landing page with campaign code, does not go through a short link, Uplifter *will not capture* valuable clickthrough data.

**Short link (Recommended)** - Will create a short link that redirects to the landing page with campaign code, *capturing* valuable clickthrough data.
`,
}
