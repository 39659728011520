import { siteContainerWidth } from '../core/variables'

interface Props {
  children?: React.ReactNode
  width?: number
}

export default function Layout({
  children,
  width = siteContainerWidth,
}: Props) {
  return (
    <div style={{ maxWidth: `${width}px`, margin: 'auto' }}>{children}</div>
  )
}
