import classNames from 'classnames'
import shortid from 'shortid'

import styles from '../styles/links-table.module.scss'

interface TableLinkProps {
  campaignLink: string
  active?: boolean
  showHighlight?: boolean
  className?: string
}

export function TableLink({
  className,
  campaignLink,
  active = false,
  showHighlight,
}: TableLinkProps): React.ReactElement {
  const searchRegEx = /([^=&]+)(=([^&]*))?/gi

  const splitLink = campaignLink.split('?')
  const useCampaignLink =
    splitLink.length === 1 ? splitLink[0] : splitLink.slice(1).join('?')
  const found = useCampaignLink.match(searchRegEx)
  const useHighlight =
    found !== null &&
    (found.length > 1 || (found.length === 1 && splitLink.length > 1))

  return (
    <span className={classNames(styles.tableLinkWrapper, className)}>
      {`${splitLink.length > 1 ? `${splitLink[0]}?` : ''}`}
      <span
        className={classNames({
          [styles.elementActive]: active,
        })}
      >
        {useHighlight &&
          found &&
          found.map((foundQueryValueString, partsIndex) => {
            const parts = foundQueryValueString.split('=')
            return parts.map((l, indx) => {
              const renderLink = `${
                indx === 0 && partsIndex > 0 ? '&' : ''
              }${l}${parts.length > 1 && indx === 0 ? '=' : ''}`

              return (
                <span
                  key={shortid.generate()}
                  className={classNames(styles.tableLink, {
                    [styles.codeHighlighted]: indx > 0 && showHighlight,
                  })}
                >
                  {renderLink}
                </span>
              )
            })
          })}
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        {!useHighlight && <>{useCampaignLink}</>}
      </span>
    </span>
  )
}
