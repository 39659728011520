import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import Button, { ButtonRow } from './button'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'
import { Checkbox } from './inputs'
import { TableLink } from './links-table'
import { Box } from './tooltip'
import styles from '../styles/campaign-code-generator-results.module.scss'
import { copyString } from '../utils'
import { downloadCodes, getCsvString } from '../utils/download-csv'
import CreateQrModal from './qr-code'
import gaEvent from '../utils/tracking'
import ConfirmationButtons from './confirmation-buttons'

interface CodesListProps {
  generatedCode?: string
  codes: GeneratedCode[]
  setCodes: Dispatch<SetStateAction<GeneratedCode[]>>
  checkedFields: string[]
  onChecked: (cID: string[], status: boolean) => void
  imageFile: string
  setImageFile: Dispatch<SetStateAction<string>>
}

const YESTERDAY = moment().subtract(1, 'days').startOf('day')

export function CodesList({
  generatedCode,
  codes = [],
  setCodes,
  checkedFields,
  onChecked,
  imageFile,
  setImageFile,
}: CodesListProps) {
  const [animatedClass, setAnimatedClass] = useState('')
  const [codesCounter, setCodesCounter] = useState<number | null>(null)
  const [active, setActive] = useState('')
  const [showQrModal, setShowQrModal] = useState(false)
  const [qrCode, setQrCode] = useState('')

  const firstCreatedNow =
    typeof generatedCode === 'string' &&
    codes.length > 0 &&
    codes[0].fullUrl === generatedCode

  useEffect(() => {
    if (
      codesCounter === null ||
      (codesCounter !== null && codesCounter !== codes.length)
    ) {
      setCodesCounter(codes.length)
      if (firstCreatedNow) {
        setAnimatedClass(styles.animatedFirstLi)
        window.setTimeout(() => {
          setAnimatedClass(styles.animatedFirstLiFinished)
        }, 1200)
      }
    }
  }, [codes])

  if (codes.length === 0) return null

  return (
    <>
      <ul
        className={classNames(animatedClass, styles.codeList, {
          [styles.scroll]: codes.length > 3,
        })}
      >
        {codes.map((item, index): React.ReactElement | null => {
          let showTime = ''
          const mT = moment(item.created)
          const days = moment().diff(mT, 'days')
          const isYesterday = mT.isSame(YESTERDAY, 'd')
          if (firstCreatedNow && index === 0) {
            showTime = 'just now'
          } else if (isYesterday) {
            showTime = 'yesterday'
          } else if (days === 0) {
            showTime = `at ${mT.format('h:mma')}`
          } else {
            showTime = `${mT.format('ddd Do MMM, h:mma')}`
          }

          let isSingleChecked = true
          const cID = item.id || null
          if (cID && checkedFields.indexOf(cID) === -1) {
            isSingleChecked = false
          }

          const displayLink = item.fullUrl.replace(
            /(^https:\/\/www\.)|(^https:\/\/)|(^http:\/\/)/i,
            '',
          )

          return (
            <li
              key={item.id}
              className={styles.codeListItem}
              onMouseLeave={(): void => setActive('')}
            >
              <div className={styles.showRow}>
                {active === item.fullUrl && (
                  <div className={styles.boxContainer}>
                    <Box
                      className={styles.box}
                      message={
                        <TableLink
                          className={styles.tableLink}
                          active={false}
                          campaignLink={item.fullUrl}
                          showHighlight
                        />
                      }
                    />
                  </div>
                )}
                <Checkbox
                  id={item.id}
                  name="selectItem"
                  checked={isSingleChecked}
                  className={styles.selectItem}
                  label=" "
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const { checked } = e.target as HTMLInputElement
                    if (cID) {
                      onChecked([cID], checked)
                    }
                  }}
                />
                <div
                  className={styles.hoverable}
                  onMouseOver={(): void => setActive(item.fullUrl || '')}
                  onMouseLeave={(): void => setActive('')}
                  onFocus={(): void => setActive(item.fullUrl || '')}
                  onBlur={(): void => setActive('')}
                >
                  <span className={styles.linkCode}>{displayLink}</span>
                </div>
                <div className={styles.inlineDate}>
                  <span>{showTime}</span>
                </div>
              </div>
              <div className={styles.hoverOptions}>
                <Button
                  className={styles.qrButton}
                  type="qrCode"
                  align="left"
                  onClick={(): void => {
                    setQrCode(item.fullUrl)
                    setShowQrModal(true)
                  }}
                />
                <Button
                  className={styles.copyButton}
                  type="copy"
                  align="left"
                  onClick={(): void => {
                    const copyLink = item.fullUrl
                    copyString(copyLink)
                  }}
                />
                <DeleteButtonWithConfirmation
                  onClick={(): void => {
                    setCodes((currCodes) => {
                      const newCodes = JSON.parse(JSON.stringify(currCodes))
                      newCodes.splice(index, 1)

                      return newCodes
                    })

                    const lsCodes = window.localStorage.getItem('prevCodes')

                    if (lsCodes === null) return

                    const newPrevCodes = JSON.parse(lsCodes) as GeneratedCode[]

                    window.localStorage.setItem(
                      'prevCodes',
                      JSON.stringify(
                        newPrevCodes.filter(
                          (code) => code.fullUrl !== item.fullUrl,
                        ),
                      ),
                    )
                  }}
                >
                  <p>Delete link?</p>
                </DeleteButtonWithConfirmation>
              </div>
            </li>
          )
        })}
      </ul>
      {showQrModal && (
        <CreateQrModal
          setShowModal={setShowQrModal}
          code={qrCode}
          imageFile={imageFile}
          setImageFile={setImageFile}
        />
      )}
    </>
  )
}

interface Props {
  generatedCode: string
  codes?: GeneratedCode[]
  setCodes: Dispatch<SetStateAction<GeneratedCode[]>>
  imageFile: string
  setImageFile: Dispatch<SetStateAction<string>>
}

export default function GeneratedCodeResults({
  generatedCode,
  codes = [],
  setCodes,
  imageFile,
  setImageFile,
}: Props): React.ReactElement | null {
  const [checkedFields, setCheckedFields] = useState<string[]>([])
  const [deleteAllActive, setDeleteAllActive] = useState(false)

  useEffect(() => {
    const fullCodesIds = codes.map((code) => code.id)

    setCheckedFields(fullCodesIds)
  }, [codes])

  return (
    <>
      <h2>Your recently created links</h2>
      <CodesList
        generatedCode={generatedCode}
        codes={codes}
        setCodes={setCodes}
        checkedFields={checkedFields}
        onChecked={(cIDs, checked) => {
          let useFields = [...checkedFields]
          cIDs.forEach((item) => {
            if (checked) {
              if (checkedFields.indexOf(item) === -1) {
                useFields = [...useFields, item]
              }
            } else {
              useFields = useFields.filter((cID) => !(item === cID))
            }
          })
          setCheckedFields(useFields)
        }}
        imageFile={imageFile}
        setImageFile={setImageFile}
      />
      <ButtonRow centerAlign>
        <span className={styles.buttonWrapper}>
          <div
            className={classNames(styles.confirmDeleteAll, {
              [styles.deleteActionActive]: deleteAllActive,
            })}
          >
            <Button
              type="flatBlue"
              className={styles.deleteAllButton}
              onClick={(): void => setDeleteAllActive(true)}
            >
              Delete all
            </Button>
            <div className={styles.content}>
              {deleteAllActive && (
                <>
                  <p>Really delete all?</p>
                  <ConfirmationButtons
                    type="compact"
                    className={styles.confirmationButtons}
                    onYes={(): void => {
                      setDeleteAllActive(false)

                      setCodes([])

                      window.localStorage.removeItem('prevCodes')
                    }}
                    onNo={(): void => {
                      setDeleteAllActive(false)
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {!deleteAllActive && (
            <Button
              disabled={false}
              onClick={async () => {
                const csv = getCsvString(checkedFields, codes)
                await downloadCodes(csv)

                gaEvent('download_codes')
              }}
            >
              Download
            </Button>
          )}
        </span>
      </ButtonRow>
    </>
  )
}
